import colors from './colors';

const MuiFormLabel = {
    root: {
        textTransform: 'capitalize',
        color: colors.ui70,
        '&$focused': {
            color: colors.ui70,
        },
    },
};

export default MuiFormLabel;
