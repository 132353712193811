import React, { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import axios from 'axios';
import FileUploader, { FileType } from '../../components/file-uploader/FileUploader';
import LinearProgress from '../../components/linear-progress/LinearProgress';
import Modal from '../../components/modal/Modal';
import { GET_AUDIT_REPORT_UPLOAD_LINK, UPDATE_AUDIT_REPORT_UPLOAD_STATUS } from '../../queries/auditReport-query';
import { actions as globalMessagesActions } from '../../state/global-messages/globalMessages-slice';
import { AuditReportStatus } from '../../constants/AuditReportVerification';

type OwnProps = {
    onClose: () => void;
    onUpload: Function;
};

const AuditReportUploadModal: FC<OwnProps> = ({ onClose, onUpload }) => {
    const { t } = useTranslation();

    const [files, setFiles] = useState<Array<FileType>>([]);
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const [getAuditReportUploadLink] = useLazyQuery(GET_AUDIT_REPORT_UPLOAD_LINK);
    const [updateAuditReportUploadStatus] = useLazyQuery(UPDATE_AUDIT_REPORT_UPLOAD_STATUS);

    const dispatch = useDispatch();

    const updateUploadStatus = async (fileUuid, status, statusDetails = '') => {
        const { error } = await updateAuditReportUploadStatus({
            variables: { fileUuid, status, statusDetails },
        });
        if (error) {
            handleUploadFailed(error);
        }
    };

    const handleUploadFailed = useCallback(
        error => {
            dispatch(
                globalMessagesActions.globalMessageAdded({
                    severity: 'error',
                    title: t('audit-report-upload-modal.error.upload-failed'),
                    content: error?.message || '',
                })
            );
            setUploading(false);
        },
        [dispatch, setUploading, t]
    );

    const handleUploadSuccess = useCallback(() => {
        dispatch(
            globalMessagesActions.globalMessageAdded({
                severity: 'success',
                content: t('audit-report-upload-modal.success.upload-successful'),
            })
        );
        setUploading(false);
        onUpload();
    }, [dispatch, onUpload, setUploading, t]);

    const uploadReport = async () => {
        setUploading(true);
        setUploadProgress(0);
        const uploadFile = files[0];

        const { data, error } = await getAuditReportUploadLink({
            variables: { fileName: uploadFile.name },
        });

        if (error) {
            handleUploadFailed(error);
            onClose();
            return;
        }

        axios
            .put(data.getAuditReportUploadLink.uploadUrl, uploadFile, {
                onUploadProgress: progressEvent => {
                    const percentCompleted = Math.round(
                        (100 * (progressEvent.loaded || 0)) / (progressEvent.total || 1)
                    );
                    setUploadProgress(percentCompleted);
                },
            })
            .then(() => {
                updateUploadStatus(data.getAuditReportUploadLink.fileUuid, AuditReportStatus.UPLOADED.value);
                handleUploadSuccess();
            })
            .catch(error => {
                updateUploadStatus(
                    data.getAuditReportUploadLink.fileUuid,
                    AuditReportStatus.UPLOAD_FAILED.value,
                    error?.message
                );
                handleUploadFailed(error);
                onUpload();
            });
    };

    const title = useMemo(
        () =>
            uploading
                ? t('audit-report-upload-modal.title.uploading')
                : t('audit-report-upload-modal.title.upload-audit-report'),
        [uploading, t]
    );

    return (
        <Modal
            closeButtonLabel={t('audit-report-upload-modal.button.cancel')}
            disableSubmitButton={files.length === 0}
            hideButtons={uploading}
            onClose={onClose}
            onSubmit={uploadReport}
            title={title}
            submitButtonLabel={t('audit-report-upload-modal.button.upload')}
            data-testid='audit-report-upload-modal'
        >
            {uploading ? (
                <LinearProgress progress={uploadProgress} />
            ) : (
                <FileUploader
                    accept='.zip, .gz'
                    files={files}
                    label={t('audit-report-upload-modal.file-uploader.label.report')}
                    onChange={setFiles}
                    placeholder={t('audit-report-upload-modal.file-uploader.placeholder.browse-to-upload-report')}
                />
            )}
        </Modal>
    );
};

export default AuditReportUploadModal;
