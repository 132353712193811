import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu as MuiMenu, MenuItem, menuClasses, menuItemClasses, styled } from '@mui/material';
import shadows from '@nuix/common-ui/dist/styled-components/themes/shadows';
import Auth from '../../auth/auth';

const Menu = styled(MuiMenu)({
    [`.${menuClasses.paper}`]: {
        boxShadow: shadows[2],
    },
    [`.${menuClasses.list}`]: {
        padding: '4px 0',
    },
    [`.${menuItemClasses.root}`]: {
        minHeight: '32px',
        padding: '4px 8px 4px 20px',
    },
});

type OwnProps = {
    anchorEl: Element | null;
    open: boolean;
    onClose: () => void;
};

const UserMenu: FC<OwnProps> = ({ anchorEl, open, onClose }) => {
    const { t } = useTranslation();

    const handleLogout = (): void => {
        Auth.handleLogout();
    };

    return (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 0,
                horizontal: 0,
            }}
            open={open}
            onClose={onClose}
        >
            <MenuItem data-testid='logout-button' onClick={handleLogout}>
                {t('user-menu.log-out')}
            </MenuItem>
        </Menu>
    );
};

export default UserMenu;
