import React from 'react';
import DataUsage from "./icons/js/DataUsage";
import Licensing from './icons/js/Licensing';
import NuixNeo from './icons/js/NuixNeo';
import Portal from './icons/js/Portal';

export const Icon = {
    DataUsage: 'DataUsage',
    Licensing: 'Licensing',
    NuixNeo: 'NuixNeo',
    Portal: 'Portal',
};

export default function createIcon({ id, color }) {
    switch (id) {
        case Icon.DataUsage: return <DataUsage color={color} pointerEvents={'none'}/>;
        case Icon.Licensing: return <Licensing color={color} pointerEvents={'none'}/>;
        case Icon.NuixNeo: return <NuixNeo color={color} pointerEvents={'none'}/>;
        case Icon.Portal: return <Portal pointerEvents={'none'}/>;
        default:
    }
    return undefined;
}
