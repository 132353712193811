import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { getActiveSessionColumns } from '../../constants/ActiveSession';
import Table from '../../components/table/Table';
import { GET_PG_ACTIVE_SESSIONS } from '../../queries/activeSessions-query';
import { actions } from '../../state/active-sessions/activeSessions-slice';
import { RootState } from '../../state/store';
import { handleError } from '../../utils/error-utils';
import LoadingOverlay from '../loading-overlay/LoadingOverlay';
import ActiveSessionReleaseConfirmationModal from './ActiveSessionReleaseConfirmationModal';

const ActiveSessions: FC = () => {
    const { i18n } = useTranslation();
    const [pageNumber, setPageNumber] = useState(0);
    const dispatch = useDispatch();
    const pageSize = useSelector((state: RootState) => state.activeSessions.pageSize);
    const pageSort = useSelector((state: RootState) => state.activeSessions.pageSort);

    const [currentRow, setCurrentRow] = useState(null);
    const [isReleaseConfirmationModalOpen, setIsReleaseConfirmationModalOpen] = useState(false);

    const handlePageSizeChanged = useCallback(
        value => {
            setPageNumber(0);
            dispatch(actions.updatePageSize(value));
        },
        [dispatch, setPageNumber]
    );

    const handlePageSortChanged = useCallback(
        value => {
            setPageNumber(0);
            dispatch(actions.updatePageSort(value));
        },
        [dispatch, setPageNumber]
    );

    const showConfirmationPopup = row => {
        setCurrentRow(row);
        setIsReleaseConfirmationModalOpen(true);
    };

    const columns = useMemo(() => getActiveSessionColumns(i18n, showConfirmationPopup), [i18n]);

    const { data, loading, error, refetch } = useQuery(GET_PG_ACTIVE_SESSIONS, {
        variables: { size: pageSize, pageNumber, pageSort },
    });

    const afterSessionRelease = useCallback(() => {
        setIsReleaseConfirmationModalOpen(false);
        setTimeout(() => {
            refetch();
        }, 500);
    }, [refetch, setIsReleaseConfirmationModalOpen]);

    if (loading) {
        return <LoadingOverlay />;
    }

    if (error) {
        return handleError(error, i18n);
    }

    return (
        <>
            <Table
                columns={columns}
                getRowId={row => row.sessionToken}
                onPageNumberChanged={setPageNumber}
                onPageSizeChanged={handlePageSizeChanged}
                onPageSortChange={handlePageSortChanged}
                pageNumber={pageNumber}
                pageSize={pageSize}
                pageSort={pageSort}
                result={data?.getAuthorizedFulfillments}
            />
            {isReleaseConfirmationModalOpen && currentRow && (
                <ActiveSessionReleaseConfirmationModal
                    onClose={() => setIsReleaseConfirmationModalOpen(false)}
                    onRelease={afterSessionRelease}
                    row={currentRow}
                />
            )}
        </>
    );
};

export default ActiveSessions;
