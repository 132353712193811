import React, { FC, useCallback, useMemo, useState } from 'react';
import {
    Autocomplete as MuiAutocomplete,
    autocompleteClasses,
    Checkbox,
    inputBaseClasses,
    styled,
} from '@mui/material';
import { applyOpacityToHex, TextField } from '@nuix/common-ui';
import { MuiExpandMoreIcon } from '@nuix/nomi-icons';
import colors from '../../theme/colors';
import FilterOptions from './FilterOptions';

const PREFIX = 'FilterWithSelectAll';
const classes = {
    displayMessage: `${PREFIX}__display-message`,
};

const Autocomplete = styled(MuiAutocomplete, {
    shouldForwardProp: prop => prop !== 'hasValue' && prop !== 'width',
})<{ hasValue: boolean; width: string }>(({ hasValue, width }) => ({
    width,
    [`.${autocompleteClasses.input}`]: {
        display: hasValue ? 'none' : 'block',
        '&:hover': {
            border: 0,
        },
    },
    [`.${inputBaseClasses.root}`]: {
        height: '32px',
        '&:focus, &:focus-within': {
            borderColor: colors.primary60,
            outline: `3px solid ${applyOpacityToHex(colors.primary60, 0.24)}`,
        },
    },
    [`.${classes.displayMessage}`]: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        '&:focus': {
            outline: 'none',
        },
    },
}));

const getDisplayValue = (options, filters) =>
    filters.length === 0
        ? ''
        : options
              .filter(option => filters.includes(option.value))
              .map(option => option.label)
              .join(', ');

export type OptionType = {
    icon?: React.ReactNode;
    label: string;
    value: string;
};

type OwnProps = {
    filters?: string[];
    onFilterChanges: (filters: string[]) => void;
    options: OptionType[];
    selectAllLabel: string;
    width?: string;
};

const FilterWithSelectAll: FC<OwnProps> = ({
    filters = [],
    onFilterChanges,
    options,
    selectAllLabel,
    width = '160px',
}) => {
    const allFilters = useMemo(() => options.map(option => option.value), [options]);

    const [selectAll, setSelectAll] = useState(filters?.length === options.length);
    const [open, setOpen] = useState(false);

    const closePopper = useCallback(() => setOpen(false), []);

    const handleSelectAll = useCallback(() => {
        onFilterChanges(selectAll ? [] : allFilters);
        setSelectAll(!selectAll);
    }, [selectAll, allFilters, onFilterChanges, setSelectAll]);

    const handleFilterChanged = useCallback(
        value => {
            const newFilters = [...filters];
            const index = newFilters.indexOf(value);
            if (index > -1) {
                newFilters.splice(index, 1);
            } else {
                newFilters.push(value);
            }
            onFilterChanges([...newFilters].sort());
            setSelectAll(newFilters.length === options.length);
        },
        [filters, options, onFilterChanges, setSelectAll]
    );

    const PaperComponent = useCallback(
        paperProps => (
            <FilterOptions
                {...paperProps}
                handleSelectAll={handleSelectAll}
                indeterminate={filters?.length > 0 && filters.length < options.length}
                selectAll={selectAll}
                selectAllLabel={selectAllLabel}
                closePopper={closePopper}
            />
        ),
        [filters, options, selectAll, handleSelectAll, selectAllLabel, closePopper]
    );

    return (
        <Autocomplete
            multiple
            options={options}
            disableClearable
            open={open}
            onOpen={() => setOpen(true)}
            onClose={closePopper}
            value={filters}
            isOptionEqualToValue={(option: OptionType, value) => option.value === value}
            hasValue={filters.length > 0}
            width={width}
            selectOnFocus={false}
            popupIcon={<MuiExpandMoreIcon />}
            renderInput={params => {
                return (
                    <TextField
                        {...params}
                        InputLabelProps={{
                            ...params.InputLabelProps,
                        }}
                        InputProps={{
                            ...params.InputProps,
                        }}
                    />
                );
            }}
            renderTags={value => {
                const displayMessage =
                    value.length === options.length ? selectAllLabel : getDisplayValue(options, value);
                return (
                    <div
                        title={displayMessage}
                        onClick={() => setOpen(!open)}
                        tabIndex={0}
                        className={classes.displayMessage}
                    >
                        {displayMessage}
                    </div>
                );
            }}
            renderOption={(props, option: OptionType) => (
                <li key={option.value} onClick={() => handleFilterChanged(option.value)}>
                    <Checkbox checked={filters.includes(option.value)} />
                    {option.icon}
                    <div title={option.label}>{option.label}</div>
                </li>
            )}
            PaperComponent={PaperComponent}
        />
    );
};

export default FilterWithSelectAll;
