import React, { FC } from 'react';
import {
    autocompleteClasses,
    Checkbox,
    checkboxClasses,
    ClickAwayListener,
    Divider,
    dividerClasses,
    Paper as MuiPaper,
    styled,
    svgIconClasses,
} from '@mui/material';
import { applyOpacityToHex } from '@nuix/common-ui';
import shadows from '@nuix/common-ui/dist/styled-components/themes/shadows';
import colors from '../../theme/colors';

const classes = {
    list: 'filter-options_list',
};

const Paper = styled(MuiPaper)({
    boxShadow: shadows[2],
    padding: '4px 0',
    [`.${classes.list}, .${autocompleteClasses.listbox} li`]: {
        padding: '0 20px 0 16px',
        height: '32px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: '4px',
        '&:hover': {
            backgroundColor: applyOpacityToHex(colors.primary60, 0.08),
        },
        svg: {
            height: '20px',
            width: '20px',
        },
        div: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    },
    [`.${autocompleteClasses.listbox}`]: {
        padding: 0,
    },
    [`.${dividerClasses.root}`]: {
        margin: '4px 12px',
    },
    [`.${checkboxClasses.root}`]: {
        margin: '4px 0 4px 4px',
        padding: '4px',
        [`.${svgIconClasses.root}`]: {
            height: '24px',
            width: '24px',
        },
    },
});

type OwnProps = {
    children: React.ReactNode[];
    closePopper: () => void;
    handleSelectAll: () => void;
    indeterminate: boolean;
    selectAll: boolean;
    selectAllLabel: string;
};

const FilterOptions: FC<OwnProps> = ({
    children,
    closePopper,
    handleSelectAll,
    indeterminate,
    selectAll,
    selectAllLabel,
    ...restProps
}) => {
    return (
        <ClickAwayListener onClickAway={closePopper}>
            <Paper {...restProps} onMouseDown={e => e.preventDefault()}>
                <div className={classes.list} onClick={handleSelectAll}>
                    <Checkbox checked={selectAll} indeterminate={indeterminate} />
                    <div title={selectAllLabel}>{selectAllLabel}</div>
                </div>
                <Divider />
                {children}
            </Paper>
        </ClickAwayListener>
    );
};

export default FilterOptions;
