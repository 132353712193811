const MuiStepLabel = {
    label: {
        fontSize: '12px',
        '&$alternativeLabel': {
            marginTop: '12px',
            color: 'white',
        },
        '&$active': {
            color: 'white',
        },
    },
    active: {},
};

export default MuiStepLabel;
