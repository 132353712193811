import React, { FC, useCallback, useMemo, useState } from 'react';
import { drawerClasses, styled, listItemButtonClasses, listItemIconClasses, listItemTextClasses } from '@mui/material';
import {
    DrawerListItemButton,
    DrawerListItemIcon,
    DrawerListItemText,
    SideDrawer as NomiSideDrawer,
} from '@nuix/common-ui';
import Avatar, { avatarClasses } from '../../components/avatar/Avatar';
import Modules from '../../module/modules';
import Session from '../../session/session';
import colors from '../../theme/colors';
import UserMenu from '../user/UserMenu';
import SideDrawerListItem from './SideDrawerListItem';
import SideDrawerTitle from './SideDrawerTitle';

const PREFIX = 'SideDrawer';
const classes = {
    footerIcon: `${PREFIX}__footer-icon`,
};

const Drawer = styled(NomiSideDrawer)({
    [`.${drawerClasses.paper}`]: {
        backgroundColor: colors.ui80,
        borderRightColor: colors.ui30,
        color: colors.ui00,
    },
    [`.${listItemTextClasses.root}`]: {
        paddingLeft: 0,
    },
    [`.${listItemTextClasses.primary}`]: {
        color: colors.ui00,
    },
    [`.${classes.footerIcon}`]: {
        padding: '8px 8px 4px 6px',
    },
    a: {
        textDecoration: 'none',
    },
    '.footer-list': {
        [`.${listItemIconClasses.root}`]: {
            padding: '8px',
        },
        [`.${listItemTextClasses.root}`]: {
            paddingLeft: '4px',
        },
        [`.Mui-focusVisible .${avatarClasses.base}`]: {
            border: `2px solid ${colors.primary30}`,
        },
        '.SideDrawer__footer-icon': {
            padding: '0 8px',
            outline: 'none!important',
        },
        [`.${listItemButtonClasses.root}.drawer-toggle-button`]: {
            padding: '4px 10px',
            [`.${listItemIconClasses.root}`]: {
                padding: 0,
            },
            svg: {
                color: colors.ui00,
                height: '16px',
                width: '16px',
            },
            '&:hover, &:active': {
                [`.${listItemIconClasses.root}`]: {
                    backgroundColor: colors.ui60,
                },
            },
            [`&.Mui-focusVisible .${listItemIconClasses.root}`]: {
                border: `3px solid ${colors.primary30}`,
                backgroundColor: colors.ui80,
            },
            [`&.Mui-focusVisible:active .${listItemIconClasses.root}`]: {
                border: `3px solid ${colors.primary30}`,
                backgroundColor: colors.ui60,
            },
        },
    },
});

const moduleListItems = Object.keys(Modules).map((moduleKey: string) => (
    <SideDrawerListItem key={moduleKey} moduleKey={moduleKey} />
));

const listItems = [<SideDrawerTitle key='title' />, ...moduleListItems];

const SideDrawer: FC = () => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);

    const openUserMenu = Boolean(userMenuAnchorEl);

    // side drawer user menu
    const handleUserMenuOpen = useCallback((event): void => {
        setUserMenuAnchorEl(event.currentTarget);
    }, []);

    const handleUserMenuClose = useCallback((): void => {
        setUserMenuAnchorEl(null);
    }, []);

    const footerListItems = useMemo(() => {
        const userName = Session.getLoggedInUser().userName;
        return [
            <DrawerListItemButton disableRipple key='Logout' onClick={handleUserMenuOpen} data-testid='user-menu'>
                <DrawerListItemIcon className={classes.footerIcon}>
                    <Avatar initials={userName && userName.charAt(0)} />
                </DrawerListItemIcon>
                <DrawerListItemText primary={userName} />
            </DrawerListItemButton>,
        ];
    }, [handleUserMenuOpen]);

    return (
        <>
            <Drawer
                isOpen={openDrawer}
                setIsOpen={setOpenDrawer}
                listItems={listItems}
                footerListItems={footerListItems}
            />
            <UserMenu anchorEl={userMenuAnchorEl} open={openUserMenu} onClose={handleUserMenuClose} />
        </>
    );
};

export default SideDrawer;
