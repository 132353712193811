import * as React from 'react';

const Licensing = ({ color = '#EEF0F5', ...rest }) => (
    <svg width={24} height={24} viewBox="0 0 24 24" {...rest}>
        <path d="M3.117 17.114a2.375 2.375 0 0 0 1.72.704h14.317a2.33 2.33 0 0 0 1.72-.704 2.375 2.375 0 0 0 .704-1.72V5.651a2.33 2.33 0 0 0-.704-1.72 2.375 2.375 0 0 0-1.72-.704H4.837a2.33 2.33 0 0 0-1.72.704 2.375 2.375 0 0 0-.704 1.72v9.743c0 .677.229 1.244.704 1.72Zm-1.299 3.357a.988.988 0 0 0 .732.302h18.892a.988.988 0 0 0 .732-.302c.201-.201.302-.439.302-.732s-.101-.531-.302-.723a1.008 1.008 0 0 0-.732-.284H2.55c-.293 0-.531.091-.732.284-.192.192-.293.43-.293.723s.091.531.293.732ZM9.951 7.307a2.89 2.89 0 0 1 4.044 0 2.737 2.737 0 0 1 .832 2.031 2.661 2.661 0 0 1-.833 2.003 2.92 2.92 0 0 1-4.044 0 2.68 2.68 0 0 1-.833-2.003 2.752 2.752 0 0 1 .832-2.031Zm-3.879 9.24a6.974 6.974 0 0 1 2.47-2.113 7.247 7.247 0 0 1 3.431-.814 7.247 7.247 0 0 1 3.431.814 6.974 6.974 0 0 1 2.47 2.113H6.072Z"
              fill={color}
        />
    </svg>
);

export default Licensing;