import React, { FC } from 'react';
import { Alert as MuiAlert, AlertColor, AlertProps, AlertTitle, alertTitleClasses, styled } from '@mui/material';
import { alertDefaultProps } from '@nuix/common-ui/dist/styled-components/themes/components/alert';
import colors from '../../theme/colors';

const getIcon = (severity: AlertColor, tooltip?: string) => {
    const icon = alertDefaultProps?.iconMapping?.[severity];
    return tooltip ? <div title={tooltip}>{icon}</div> : icon;
};

const Alert = styled(MuiAlert)({
    border: '0px !important',
    borderRadius: 0,
    padding: '6px 32px !important',
    [`.${alertTitleClasses.root}`]: {
        color: colors.ui80,
        fontSize: '12px',
        fontWeight: 600,
        margin: 0,
    },
});

interface OwnProps extends AlertProps {
    title?: string;
    tooltip?: string;
}

const Banner: FC<OwnProps> = ({ children, severity, title, tooltip, ...restProps }) => {
    return (
        <Alert icon={getIcon(severity ?? 'success', tooltip)} severity={severity} {...restProps}>
            {title && <AlertTitle>{title}</AlertTitle>}
            {children}
        </Alert>
    );
};

export default Banner;
