import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Button } from '@mui/material';
import { Dayjs } from 'dayjs';
import Export from '../../assets/icons/js/Export';
import ConsumptionUsageSummary from '../../components/consumption-usage-summary/ConsumptionUsageSummary';
import SingleInputDateRangePicker from '../../components/date-picker/SingleInputDateRangePicker';
import FilterWithSelectAll from '../../components/filter-with-select-all/FilterWithSelectAll';
import SearchInput from '../../components/search-input/SearchInput';
import Table from '../../components/table/Table';
import { getConsumptionByDongleColumns } from '../../constants/ConsumptionByDongle';
import {
    GET_CONSUMPTION_BY_DONGLE,
    GET_CONSUMPTION_USAGE_SUMMARY_BY_DONGLE,
    GET_OFFLINE_LICENCE_SUMMARY,
} from '../../queries/consumptionByDongle-query';
import { actions } from '../../state/consumption-by-dongle/consumptionByDongle-slice';
import { RootState } from '../../state/store';
import { handleError } from '../../utils/error-utils';
import LoadingOverlay from '../loading-overlay/LoadingOverlay';
import { mainBoxClasses } from '../main-box/MainBox';

const ConsumptionByDongle: FC = () => {
    const { i18n, t } = useTranslation();

    const dispatch = useDispatch<any>();
    const consumptionUsageSummary = useSelector(
        (state: RootState) => state.consumptionByDongle.consumptionUsageSummary
    );
    const dongleOptions = useSelector((state: RootState) => state.consumptionByDongle.dongleOptions);
    const downloading = useSelector((state: RootState) => state.consumptionByDongle.downloading);
    const pageSize = useSelector((state: RootState) => state.consumptionByDongle.pageSize);
    const pageSort = useSelector((state: RootState) => state.consumptionByDongle.pageSort);

    const [getConsumptionUsageSummary] = useLazyQuery(GET_CONSUMPTION_USAGE_SUMMARY_BY_DONGLE);
    const [getOfflineLicenceSummary] = useLazyQuery(GET_OFFLINE_LICENCE_SUMMARY);
    const [pageNumber, setPageNumber] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [fromDateTime, setFromDateTime] = useState<Dayjs | null>(null);
    const [toDateTime, setToDateTime] = useState<Dayjs | null>(null);
    const [firstLoad, setFirstLoad] = useState(true);
    const [dongleNameFilters, setDongleNameFilters] = useState(dongleOptions.map(option => option.value));

    useEffect(() => {
        const getConsumptionUsage = async (dongleNames: string[]) => {
            const { data } = await getConsumptionUsageSummary({
                variables: { dongleNames },
            });
            if (data?.getConsumptionUsageSummary) {
                dispatch(actions.setConsumptionUsageSummary(data.getConsumptionUsageSummary));
            }
        };

        const getDongleOptions = async () => {
            const { data } = await getOfflineLicenceSummary();
            if (data?.getOfflineLicenceSummary) {
                const dongles = [
                    ...new Set<string>(data.getOfflineLicenceSummary.map(offlineLicence => offlineLicence.name)),
                ].sort();
                dispatch(actions.setDongleOptions(dongles));
                getConsumptionUsage(dongles);
            }
        };

        getDongleOptions();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setDongleNameFilters(dongleOptions.map(option => option.value));
    }, [dongleOptions, setDongleNameFilters]);

    const handlePageSizeChanged = useCallback(
        value => {
            setPageNumber(0);
            dispatch(actions.updatePageSize(value));
        },
        [dispatch, setPageNumber]
    );

    const handlePageSortChanged = useCallback(
        value => {
            setPageNumber(0);
            dispatch(actions.updatePageSort(value));
        },
        [dispatch, setPageNumber]
    );

    const handleExport = useCallback(() => {
        dispatch(
            actions.exportConsumptionByDongle({
                dongleNames: dongleNameFilters,
                fromDateTime: fromDateTime?.toString() || '1970-01-01T00:00:00.000Z',
                searchTerm,
                toDateTime: toDateTime?.toString() || '2999-12-31T23:59:59.999Z',
                sort: `${pageSort.fieldName},${pageSort.direction}`,
            })
        );
    }, [dispatch, dongleNameFilters, fromDateTime, searchTerm, toDateTime, pageSort]);

    const numberFormat = useMemo(() => Intl.NumberFormat('en-US'), []);
    const columns = useMemo(() => getConsumptionByDongleColumns(i18n, numberFormat), [i18n, numberFormat]);

    const { data, loading, error, refetch } = useQuery(GET_CONSUMPTION_BY_DONGLE, {
        variables: {
            dongleNames: dongleNameFilters,
            fromDateTime: fromDateTime || '1970-01-01T00:00:00.000Z',
            pageNumber,
            pageSort,
            searchTerm,
            size: pageSize,
            toDateTime: toDateTime || '2999-12-31T23:59:59.999Z',
        },
    });

    const onSearchButtonClick = useCallback(() => {
        refetch();
    }, [refetch]);

    if (loading && firstLoad) {
        setFirstLoad(false);
        return <LoadingOverlay />;
    }

    if (error) {
        return handleError(error, i18n);
    }

    return (
        <>
            <ConsumptionUsageSummary consumptionUsageSummary={consumptionUsageSummary} />
            <div className={mainBoxClasses.tableToolbar}>
                <Button startIcon={<Export />} variant='action' disabled={downloading} onClick={handleExport}>
                    {t('consumption-by-dongle.button.export')}
                </Button>
                <div className={mainBoxClasses.filters}>
                    <SingleInputDateRangePicker
                        fromDate={fromDateTime}
                        onFromDateChange={setFromDateTime}
                        onToDateChange={setToDateTime}
                        toDate={toDateTime}
                    />
                    <FilterWithSelectAll
                        filters={dongleNameFilters}
                        onFilterChanges={setDongleNameFilters}
                        options={dongleOptions}
                        selectAllLabel={t('consumption-by-dongle.filter.dongle-field.all-dongles')}
                        width='180px'
                    />
                    <SearchInput
                        onChange={e => setSearchTerm(e.target.value)}
                        onButtonClick={onSearchButtonClick}
                        value={searchTerm}
                    />
                </div>
            </div>
            <Table
                columns={columns}
                onPageNumberChanged={setPageNumber}
                onPageSizeChanged={handlePageSizeChanged}
                onPageSortChange={handlePageSortChanged}
                pageNumber={pageNumber}
                pageSize={pageSize}
                pageSort={pageSort}
                result={data?.searchConsumptionReports}
            />
        </>
    );
};

export default ConsumptionByDongle;
