import React, { FC } from 'react';
import { CircularProgress, CircularProgressProps, circularProgressClasses, styled } from '@mui/material';
import colors from '../../theme/colors';

const classes = {
    backCircle: 'in-progress-circle__back-circle',
    frontCircle: 'in-progress-circle__front-circle',
};

const Container = styled('div', {
    shouldForwardProp: prop => prop !== 'size',
})<{ size: string | number }>(({ size }) => ({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    height: `${size}px`,
    width: `${size}px`,
    [`& .${classes.backCircle}`]: {
        color: colors.ui40,
    },
    [`& .${classes.frontCircle}`]: {
        position: 'absolute',
        left: 0,
        [`& .${circularProgressClasses.circle}`]: {
            stroke: 'url(#my_gradient)',
            strokeLinecap: 'round',
        },
    },
    [`& .${circularProgressClasses.svg}`]: {
        height: `${size}px!important`,
        width: `${size}px!important`,
    },
}));

const InProgressCircle: FC<CircularProgressProps> = ({ size, ...restProps }) => {
    const circleSize = size || 16;
    return (
        <Container size={circleSize}>
            <svg width={0} height={0}>
                <defs>
                    <linearGradient id='my_gradient' x1='0%' y1='0%' x2='0%' y2='100%'>
                        <stop offset='0%' stopColor={colors.primary30} />
                        <stop offset='100%' stopColor={colors.primary50} />
                    </linearGradient>
                </defs>
            </svg>
            <CircularProgress
                className={classes.backCircle}
                variant='determinate'
                size={circleSize}
                {...restProps}
                value={100}
            />
            <CircularProgress className={classes.frontCircle} size={circleSize} {...restProps} />
        </Container>
    );
};

export default InProgressCircle;
