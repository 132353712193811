import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { NomiErrorSquareIcon } from '@nuix/nomi-icons';
import { ConsumptionUsageType } from '../../constants/Consumption';
import colors from '../../theme/colors';
import { convertByte } from '../../utils/consumption-utils';
import { getDisplayDate, isExpired } from '../../utils/date-utils';
import createIcon, { Icon } from '../../assets/asset-utils';

const PREFIX = 'ConsumptionUsage';
const classes = {
    bar: `${PREFIX}__bar`,
    container: `${PREFIX}__container`,
    content: `${PREFIX}__content`,
    details: `${PREFIX}__details`,
    title: `${PREFIX}__title`,
    titleMessage: `${PREFIX}__title-message`,
    leftPanel: `${PREFIX}__left-panel`,
    rightPanel: `${PREFIX}__right-panel`,
    rightPanelDetails: `${PREFIX}__right-panel-details`,
    error: `${PREFIX}__error`,
};

const Root = styled('div', {
    shouldForwardProp: prop => prop !== 'hasIcon',
})<{ hasIcon: boolean }>(({ hasIcon }) => ({
    backgroundColor: colors.ui00,
    borderRadius: '4px',
    padding: '12px 0',
    [`& .${classes.container}`]: {
        display: 'flex',
        flexDirection: 'row',
    },
    [`& .${classes.bar}`]: {
        width: '4px',
        backgroundColor: colors.primary50,
        borderRadius: '0px 2px 2px 0px',
    },
    [`& .${classes.content}`]: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '12px',
        padding: '0 16px',
        rowGap: '12px',
    },
    [`& .${classes.title}`]: {
        height: '20px',
        display: 'flex',
        flexDirection: 'row',
    },
    [`& .${classes.titleMessage}`]: {
        height: '16px',
        fontWeight: 600,
    },
    [`& .${classes.details}`]: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '20px',
        lineHeight: '16px',
    },
    [`& .${classes.leftPanel}`]: {
        fontWeight: 600,
        minWidth: '57px',
    },
    [`& .${classes.rightPanel}`]: {
        display: 'flex',
        flexDirection: 'row',
        whiteSpace: 'nowrap',
        svg: {
            color: colors.error80,
            paddingTop: '2px',
            height: '14px',
            width: '14px',
        },
    },
    [`& .${classes.error}`]: {
        color: colors.error80,
    },
}));

type OwnProps = {
    consumptionUsage: ConsumptionUsageType;
};

const ConsumptionUsage: FC<OwnProps> = ({ consumptionUsage }) => {
    const { expiry, name, totalCases, totalProcessed } = consumptionUsage;

    const { t } = useTranslation();

    const numberFormat = useMemo(() => Intl.NumberFormat('en-US'), []);
    const expired = useMemo(() => isExpired(expiry), [expiry]);
    const displayExpiredDate = useMemo(() => (expiry ? getDisplayDate(expiry) : ''), [expiry]);

    return (
        <Root hasIcon={expired} data-testid='consumption-usage'>
            <div className={classes.container}>
                <div className={classes.bar} />
                <div className={classes.content}>
                    <div className={classes.title}>
                        {createIcon({ id: Icon.NuixNeo, color: colors.primary50 })}
                        <div className={classes.titleMessage}>{name}</div>
                    </div>
                    <div className={classes.details}>
                        <div className={classes.leftPanel}>{convertByte(totalProcessed, numberFormat, 0)}</div>
                        <div className={classes.rightPanel}>
                            {expired && <NomiErrorSquareIcon />}
                            <div className={classes.rightPanelDetails}>
                                <div className={expired ? classes.error : ''}>
                                    {t('consumption-usage.expiration-date')} {displayExpiredDate}
                                </div>
                                <div>
                                    {t('consumption-usage.number-of-cases')} {numberFormat.format(totalCases)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Root>
    );
};

export default ConsumptionUsage;
