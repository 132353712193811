import colors from './colors';

const MuiAppBar = {
    root: {
        boxShadow: 'none',
        color: colors.ui80,
    },
    colorPrimary: {
        backgroundColor: colors.ui00,
    },
};

export default MuiAppBar;
