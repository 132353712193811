import {
    AutocompleteClasses,
    iconButtonClasses,
    inputBaseClasses,
    inputClasses,
    outlinedInputClasses,
} from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides.js';
import { applyOpacityToHex } from '@nuix/common-ui';
import { autocompleteOverrides as nomiAutocompleteOverrides } from '@nuix/common-ui/dist/styled-components/themes/components/autocomplete';
import shadows from '@nuix/common-ui/dist/styled-components/themes/shadows';
import colors from './colors';

export const autocompleteOverrides: Partial<OverridesStyleRules<keyof AutocompleteClasses>> = {
    ...nomiAutocompleteOverrides,
    option: {
        ...(nomiAutocompleteOverrides.option as object),
        minHeight: '32px!important',
    },
    root: {
        ...(nomiAutocompleteOverrides.root as object),
        border: 0,
        [`.${inputBaseClasses.root}`]: {
            // Note: border height is included in this value
            height: '32px',
            '&.MuiAutocomplete-inputRoot.MuiInputBase-adornedStart.MuiAutocomplete-onlyTagAdornments': {
                paddingLeft: '8px',
            },
            '.MuiInputAdornment-positionEnd:last-child': {
                background: 'none',
            },
            '&:hover, &:active': {
                borderColor: colors.primary60,
            },
            '&:hover:has(.Mui-disabled), &:active:has(.Mui-disabled)': {
                borderColor: colors.ui00,
            },
            [`&.${inputClasses.root}`]: {
                color: colors.ui80,
            },
            [`&.${outlinedInputClasses.root}`]: {
                color: colors.ui80,
                '&.Mui-focused': {
                    borderColor: colors.primary60,
                    outline: `3px solid ${applyOpacityToHex(colors.primary60, 0.24)}`,
                },
            },
            [`.${iconButtonClasses.root}`]: {
                padding: 0,
            },
        },
    },
    endAdornment: {
        marginRight: '4px',
    },
    paper: {
        boxShadow: shadows[2],
        color: colors.ui80,
    },
    popupIndicator: {
        svg: {
            color: colors.secondary50,
            height: '24px',
            width: '24px',
        },
    },
};
