import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import colors from '../../theme/colors';
import { convertByte } from '../../utils/consumption-utils';

const PREFIX = 'TotalUsage';
const classes = {
    totalUsage: `${PREFIX}__total-usage`,
    details: `${PREFIX}__details`,
    numberOfProcessed: `${PREFIX}__number-of-processed`,
};

const Root = styled('div')({
    backgroundColor: colors.aqua10,
    border: `1px solid ${colors.aqua60}`,
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
    padding: '12px 16px',
    minWidth: '308px',
    [`& .${classes.totalUsage}`]: {
        height: '24px',
        fontSize: '20px',
        fontWeight: 300,
    },
    [`& .${classes.details}`]: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: '12px',
        justifyContent: 'space-between',
        height: '16px',
    },
    [`& .${classes.numberOfProcessed}`]: {
        fontWeight: 600,
    },
});

type OwnProps = {
    totalCases: number;
    totalProcessed: number;
};

const TotalUsage: FC<OwnProps> = ({ totalCases, totalProcessed }) => {
    const { t } = useTranslation();

    const numberFormat = useMemo(() => Intl.NumberFormat('en-US'), []);

    return (
        <Root data-testid='total-usage'>
            <div className={classes.totalUsage}>{t('total-usage.total-usage')}</div>
            <div className={classes.details}>
                <div className={classes.numberOfProcessed}>{convertByte(totalProcessed, numberFormat, 0)}</div>
                <div>
                    {t('total-usage.number-of-cases')} {numberFormat.format(totalCases)}
                </div>
            </div>
        </Root>
    );
};

export default TotalUsage;
