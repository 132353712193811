import dateFormat from 'dateformat';
import moment from 'moment-timezone';

/**
 * Format from ISO8601 (e.g. 2012-04-23T18:25:43.511Z) as per mask.
 */
const mask = "dd mmm yyyy '-' hh:MM tt";

const fileNameMask = "yyyymmdd'_'hhMMss";

export const DEFAULT_DATE_FORMAT = 'dd/mm/yyyy';
/**
 * Format ISO8601 date string and display only date and time.
 *
 * @param dateAsString ISO8601 date string.
 * @param utc use UTC timezone if true, else use local timezone.
 * @returns formatted date as per mask.
 */
export const getDisplayDateTime = (dateAsString, utc = false) => dateFormat(new Date(dateAsString), mask, utc);
export const getDateTimeForFileName = (dateAsString, utc = false) =>
    dateFormat(new Date(dateAsString), fileNameMask, utc);

/**
 * Format ISO8601 date string and display only date.
 *
 * @param dateAsString ISO8601 date string.
 */
export const getDisplayDate = dateAsString => dateFormat(new Date(dateAsString), DEFAULT_DATE_FORMAT, false);

export const isExpired = dateAsString => new Date(dateAsString) < new Date();

export const getTimezone = () => moment.tz(moment.tz.guess()).zoneAbbr();
