import * as React from 'react';

const DataUsage = ({ color = '#EEF0F5', ...rest }) => (
    <svg width={24} height={24} viewBox="0 0 24 24" {...rest}>
        <path
            d="M20.713 3.287c-.509-.508-1.138-.762-1.888-.762H5.175c-.75 0-1.379.254-1.887.762-.509.509-.763 1.138-.763 1.888v13.65c0 .75.254 1.379.763 1.887.508.509 1.137.763 1.887.763h13.65c.75 0 1.379-.254 1.888-.763.508-.508.762-1.137.762-1.887V5.175c0-.75-.254-1.379-.762-1.888zm-2.992 13.992a.923.923 0 0 1-.676.28H6.955a.919.919 0 0 1-.676-.28.92.92 0 0 1-.279-.675c0-.264.093-.49.279-.676a.922.922 0 0 1 .676-.279h10.09c.264 0 .49.093.676.279a.92.92 0 0 1 .279.676.92.92 0 0 1-.279.675zm0-3.116a.922.922 0 0 1-.676.279H6.955a.922.922 0 0 1-.676-.279.923.923 0 0 1-.279-.676c0-.264.093-.49.279-.676a.922.922 0 0 1 .676-.279h10.09c.264 0 .49.093.676.279a.92.92 0 0 1 .279.676.923.923 0 0 1-.279.676zm.171-5.605a.918.918 0 0 1-.288.334l-3.441 2.45-3.676-2.612-3.027 1.838a.918.918 0 0 1-.973.018A.888.888 0 0 1 6 9.757c0-.168.042-.324.126-.469.084-.144.198-.264.342-.36l4.108-2.486 3.586 2.541 2.342-1.658c.312-.228.64-.252.982-.072a.892.892 0 0 1 .514.846.977.977 0 0 1-.108.459z"
            fill={color}
        />
    </svg>
);

export default DataUsage;