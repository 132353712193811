import { useGridApiContext } from '@mui/x-data-grid';
import React, { useCallback } from 'react';
import TablePagination from './TablePagination';

declare module '@mui/x-data-grid' {
    interface PaginationPropsOverrides {
        hideSelectedMessage: boolean;
        numberOfElements: number;
        onPageNumberChanged: (value: number) => void;
        onPageSizeChanged: (value: number) => void;
        pageNumber: number;
        pageSize: number;
        totalElements: number;
        totalPages: number;
    }
}

const DataGridPagination = ({
    hideSelectedMessage,
    numberOfElements,
    onPageNumberChanged,
    onPageSizeChanged,
    pageNumber,
    pageSize,
    totalElements,
    totalPages,
}) => {
    const apiRef = useGridApiContext();

    const handlePageChange = useCallback(
        newPage => {
            onPageNumberChanged(newPage - 1);
        },
        [onPageNumberChanged]
    );

    return (
        <TablePagination
            hideSelectedMessage={hideSelectedMessage}
            numberOfElements={numberOfElements}
            onPageChange={handlePageChange}
            onPageOptionChange={onPageSizeChanged}
            page={totalElements === 0 ? 0 : pageNumber + 1}
            pageSize={pageSize}
            selectedCount={apiRef.current.getSelectedRows().size}
            totalElements={totalElements}
            totalPages={totalPages}
        />
    );
};

export default DataGridPagination;
