const colors = {
    primary10: '#E5EEFC',
    primary30: '#7FAAF0',
    primary40: '#3378E9',
    primary50: '#0056E3',
    primary60: '#006DCC',
    primary70: '#2151A3',
    primary80: '#173972',

    secondary50: '#43527A',
    secondary70: '#1F273D',
    secondary80: '#050E28',

    ui00: '#FFFFFF',
    ui10: '#F7F8FA',
    ui20: '#EEF0F5',
    ui30: '#E1E5ED',
    ui40: '#C5CCD6',
    ui50: '#8E95A1',
    ui60: '#626C7A',
    ui70: '#363E48',
    ui80: '#030C17',

    success10: '#E1F5F2',
    success50: '#21A08C',
    success80: '#036A5A',
    warn10: '#FFF3D6',
    warn50: '#F2BB30',
    warn80: '#995E00',
    error10: '#FFEBEE',
    error50: '#E53D59',
    error80: '#B60825',

    aqua10: '#E6F7FA',
    aqua60: '#0FC2DF',
};

export default colors;
