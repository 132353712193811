import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    autocompleteClasses,
    IconButton,
    inputBaseClasses,
    Typography,
    typographyClasses,
    styled,
} from '@mui/material';
import { Autocomplete, TextField } from '@nuix/common-ui';
import { MuiExpandMoreIcon, MuiNavigateBeforeIcon, MuiNavigateNextIcon } from '@nuix/nomi-icons';
import { PAGE_SIZE_OPTIONS } from '../../constants/Pagination';
import colors from '../../theme/colors';

const classes = {
    center: 'table-pagination__center',
    navigateButton: 'table-pagination__navigate-button',
    pageNumberInput: 'table-pagination__page-number-input',
    pageSizeInput: 'table-pagination__page-size-input',
    pageSizeWrapper: 'table-pagination__page-size-wrapper',
    ofTotalPage: 'table-pagination__of-total-page',
    right: 'table-pagination__right',
};

const PaginationContainer = styled('div')({
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 1fr',
    alignItems: 'center',
    height: '48px',
    width: '100%',
    padding: '8px 16px',
    [`.${typographyClasses.root}`]: {
        color: colors.ui70,
        whiteSpace: 'nowrap',
        fontSize: '12px',
        fontStyle: 'italic',
    },
    [`.${classes.center}`]: {
        display: 'flex',
        columnGap: '8px',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    [`.${classes.right}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'right',
        columnGap: '2px',
    },
    [`.${classes.pageSizeWrapper}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: '8px',
        [`.${autocompleteClasses.inputRoot}`]: {
            backgroundColor: colors.ui00,
            padding: '0 2px 0 8px',
            width: '64px',
        },
        [`.${autocompleteClasses.input}`]: {
            width: '28px!important',
            minWidth: '28px!important',
        },
        [`.${autocompleteClasses.popupIndicator}`]: {
            marginRight: '4px',
        },
    },
    [`.${classes.pageNumberInput} .${inputBaseClasses.root}`]: {
        backgroundColor: colors.ui00,
    },
    [`.${classes.navigateButton} svg`]: {
        height: '16px',
        width: '16px',
    },
    [`.${classes.ofTotalPage}`]: {
        wordSpacing: '5px',
    },
});

type OwnProps = {
    hideSelectedMessage?: boolean;
    numberOfElements: number;
    onPageChange: (value) => void;
    onPageOptionChange: (value) => void;
    page: number;
    pageSize?: number;
    selectedCount: number;
    totalElements: number;
    totalPages: number;
};

const TablePagination: FC<OwnProps> = ({
    hideSelectedMessage = true,
    numberOfElements,
    onPageChange,
    onPageOptionChange,
    page,
    pageSize = 50,
    selectedCount,
    totalElements,
    totalPages,
}) => {
    const { t } = useTranslation();
    const [displayPageNumber, setDisplayPageNumber] = useState(`${page}`);

    useEffect(() => {
        setDisplayPageNumber(`${page}`);
    }, [page]);

    const from = useMemo(() => (totalElements === 0 ? 0 : (page - 1) * pageSize + 1), [page, pageSize, totalElements]);
    const to = useMemo(
        () => (totalElements === 0 ? 0 : from + numberOfElements - 1),
        [from, numberOfElements, totalElements]
    );

    const disabledPrevious = useMemo(() => page === 1 || totalElements === 0, [page, totalElements]);
    const disabledNext = useMemo(() => totalPages === page || totalElements === 0, [totalElements, totalPages, page]);
    const disabledPageInput = useMemo(() => totalPages === 1 || totalElements === 0, [totalElements, totalPages]);

    const onKeyDown = useCallback(
        e => {
            /**
             * Filtered cases:
             *   - The pressed key's value doesn't meet the browser's requirements for a number input
             *   - The pressed key isn't E (this represents an exponential number)
             *   - The pressed key includes non-numeric numpad input that isn't Enter
             */
            if (e.code === 'KeyE' || !!e.code.match(/Numpad[A-D|F-Z]/g)) {
                e.preventDefault();
            }

            if (e.code !== 'Enter' && e.code !== 'NumpadEnter') return;

            let newPage = parseInt(displayPageNumber, 10);

            if (Number.isNaN(newPage) || newPage > totalPages || newPage < 1) {
                newPage = page;
            }

            if (newPage !== page) {
                onPageChange(newPage);
            } else {
                setDisplayPageNumber(`${page}`);
            }
        },
        [page, onPageChange, totalPages, displayPageNumber, setDisplayPageNumber]
    );

    const onInputBlur = useCallback(() => {
        setDisplayPageNumber(`${page}`);
    }, [page, setDisplayPageNumber]);

    const numberFormat = Intl.NumberFormat('en-US');

    const selectedMessage = useMemo(
        () => (hideSelectedMessage ? '' : t('table-pagination.number-selected', { number: selectedCount })),
        [t, selectedCount, hideSelectedMessage]
    );

    return (
        <PaginationContainer data-testid='table-pagination'>
            <Typography>{selectedMessage}</Typography>
            <div className={classes.center}>
                <div className={classes.pageSizeWrapper}>
                    <Typography>{t('table-pagination.items-per-page')}</Typography>
                    <Autocomplete
                        disableClearable
                        required
                        value={pageSize.toString()}
                        onChange={(_, val) => onPageOptionChange(Number(val))}
                        options={PAGE_SIZE_OPTIONS}
                        popupIcon={<MuiExpandMoreIcon />}
                    />
                </div>
                <IconButton
                    data-testid='table-pagination-input-previous'
                    className={classes.navigateButton}
                    disabled={disabledPrevious}
                    onClick={() => onPageChange(page - 1)}
                >
                    <MuiNavigateBeforeIcon />
                </IconButton>
                <Typography>{t('table-pagination.page')}</Typography>
                <TextField
                    data-testid='table-pagination-input-field'
                    className={classes.pageNumberInput}
                    onBlur={onInputBlur}
                    onKeyDown={onKeyDown}
                    onChange={e => setDisplayPageNumber(e.currentTarget.value)}
                    value={displayPageNumber}
                    disabled={disabledPageInput}
                    type='number'
                    inputProps={{
                        inputMode: 'numeric',
                        type: 'number',
                        min: 1,
                        max: totalPages,
                    }}
                    filled
                />
                <Typography className={classes.ofTotalPage}>
                    {t('table-pagination.of-total-Pages', { totalPages: numberFormat.format(totalPages) })}
                </Typography>
                <IconButton
                    data-testid='table-pagination-input-next'
                    className={classes.navigateButton}
                    disabled={disabledNext}
                    onClick={() => onPageChange(page + 1)}
                >
                    <MuiNavigateNextIcon />
                </IconButton>
            </div>
            <div className={classes.right}>
                <Typography>{`${numberFormat.format(from)}-${numberFormat.format(to)}`}</Typography>
                <Typography>/</Typography>
                <Typography>{numberFormat.format(totalElements)}</Typography>
            </div>
        </PaginationContainer>
    );
};

export default TablePagination;
