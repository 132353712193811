export type MonthOption = {
    value: number;
    label: string;
};

export const getMonthOptions = (i18n): MonthOption[] => [
    {
        value: 0,
        label: i18n.t('calendar.get-month-options.january'),
    },
    {
        value: 1,
        label: i18n.t('calendar.get-month-options.february'),
    },
    {
        value: 2,
        label: i18n.t('calendar.get-month-options.march'),
    },
    {
        value: 3,
        label: i18n.t('calendar.get-month-options.april'),
    },
    {
        value: 4,
        label: i18n.t('calendar.get-month-options.may'),
    },
    {
        value: 5,
        label: i18n.t('calendar.get-month-options.june'),
    },
    {
        value: 6,
        label: i18n.t('calendar.get-month-options.july'),
    },
    {
        value: 7,
        label: i18n.t('calendar.get-month-options.august'),
    },
    {
        value: 8,
        label: i18n.t('calendar.get-month-options.september'),
    },
    {
        value: 9,
        label: i18n.t('calendar.get-month-options.october'),
    },
    {
        value: 10,
        label: i18n.t('calendar.get-month-options.november'),
    },
    {
        value: 11,
        label: i18n.t('calendar.get-month-options.december'),
    },
];

export const getYearOptions = () => Array.from({ length: new Date().getFullYear() - 2005 + 1 }, (_, i) => 2005 + i);
