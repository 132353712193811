import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppBar, styled, Tab, Tabs, Toolbar } from '@mui/material';
import Modules from '../../module/modules';
import { actions as moduleSelectionActions } from '../../state/module-selection/moduleSelection-slice';
import { RootState } from '../../state/store';
import { handleError } from '../../utils/error-utils';

const PREFIX = 'MainAppBar';
const classes = {
    tab: `${PREFIX}__tab`,
};
const Root = styled(AppBar)({
    [`& .${classes.tab}`]: {},
});

const MainAppBar: FC = () => {
    const { i18n, t } = useTranslation();
    const dispatch = useDispatch();
    const selectedModuleId = useSelector((state: RootState) => state.moduleSelection.selectedModuleId);
    const selectedModuleTabIds = useSelector((state: RootState) => state.moduleSelection.selectedModuleTabIds);
    const selectedTabId = selectedModuleTabIds[selectedModuleId];
    const selectedModule = Modules[selectedModuleId];

    const handleTabSelection = (_, tabId) => {
        dispatch(moduleSelectionActions.selectTab({ moduleId: selectedModuleId, tabId }));
    };

    if (selectedModule) {
        return (
            <Root position='relative' data-testid='main-app-bar'>
                <Toolbar>
                    <Tabs value={selectedTabId} onChange={handleTabSelection}>
                        {selectedModule.tabs.map(element => (
                            <Tab
                                key={element.id}
                                value={element.id}
                                label={element.label(i18n)}
                                component={Link}
                                to={element.link}
                                className={classes.tab}
                            />
                        ))}
                    </Tabs>
                </Toolbar>
            </Root>
        );
    }

    return handleError(t('main-app-bar.error.no-module-selected'), i18n);
};

export default MainAppBar;
