import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { listItemButtonClasses, listItemIconClasses, styled, typographyClasses } from '@mui/material';
import { DrawerListItem } from '@nuix/common-ui';
import createIcon from '../../assets/asset-utils';
import Modules, { ModuleType } from '../../module/modules';
import { getTabLink } from '../../module/module-utils';
import { actions as moduleSelectionActions } from '../../state/module-selection/moduleSelection-slice';
import { RootState } from '../../state/store';
import colors from '../../theme/colors';

const Root = styled('div')({
    [`.${listItemButtonClasses.root}`]: {
        '&:hover': {
            backgroundColor: colors.primary10,
            'svg path': {
                fill: colors.primary60,
            },
        },
        '&:active': {
            backgroundColor: colors.primary40,
            [`.${typographyClasses.root}`]: {
                color: colors.ui00,
            },
            'svg path': {
                fill: colors.ui00,
            },
            '::before': {
                content: '""',
                position: 'absolute',
                left: '0px',
                borderRight: `2px solid ${colors.ui00}`,
                borderRadius: '4px',
                display: 'block',
                width: '2px',
                height: '24px',
            },
        },
        '&.Mui-selected': {
            backgroundColor: `${colors.ui60}!important`,
            [`.${typographyClasses.root}`]: {
                color: `${colors.ui00}!important`,
            },
            'svg path': {
                fill: colors.ui20,
            },
            '::before': {
                borderRightColor: `${colors.ui00}!important`,
            },
        },
    },
    [`.${listItemIconClasses.root}`]: {
        minWidth: '44px',
    },
});

type OwnProps = {
    moduleKey: string;
};

const SideDrawerListItem: FC<OwnProps> = ({ moduleKey }) => {
    const { i18n } = useTranslation();

    const dispatch = useDispatch();
    const selectedModuleId = useSelector((state: RootState) => state.moduleSelection.selectedModuleId);
    const selectedModuleTabIds = useSelector((state: RootState) => state.moduleSelection.selectedModuleTabIds);

    const navigate = useNavigate();
    const handleListItemClick = useCallback(
        (moduleId: string) => {
            dispatch(moduleSelectionActions.selectModule({ moduleId }));
            const selectedTabId = selectedModuleTabIds[moduleId];
            if (selectedTabId) {
                const link = getTabLink(moduleId, selectedTabId);
                navigate(link);
            }
        },
        [dispatch, navigate, selectedModuleTabIds]
    );

    const module: ModuleType = useMemo(() => Modules[moduleKey], [moduleKey]);
    const label = useMemo(() => module.title(i18n), [i18n, module]);
    const icon = useMemo(() => <div title={label}>{createIcon(module.icon)}</div>, [label, module]);

    return (
        <Root data-testid='side-drawer-list-item'>
            <DrawerListItem
                selected={selectedModuleId === module.id}
                onClick={() => handleListItemClick(module.id)}
                literal={label}
                Icon={icon}
            />
        </Root>
    );
};

export default SideDrawerListItem;
