import { AlertColor } from '@mui/material';
import { createSlice } from '@reduxjs/toolkit';
import { v4 } from 'uuid';

export type GlobalMessagesType = {
    autoHideDuration: number;
    content: string;
    id: string;
    severity: AlertColor;
    title: string;
};

export type GlobalMessagesState = Readonly<{
    messages: Array<GlobalMessagesType>;
}>;

const initialState: GlobalMessagesState = {
    messages: [],
};

const globalMessagesSlice = createSlice({
    name: 'globalMessages',
    initialState,
    reducers: {
        globalMessageAdded(state, { payload }) {
            const title = payload.title;
            const content = payload.content;
            const severity = payload.severity;
            const foundMessageIndex = state.messages.findIndex(
                stateMessage =>
                    stateMessage.content === content &&
                    stateMessage.severity === severity &&
                    stateMessage.title === title
            );

            // if message does not exist, add it
            if (foundMessageIndex === -1) {
                state.messages.push({
                    autoHideDuration: payload.autoHideDuration || 5000,
                    id: payload.id || v4(),
                    title: title ?? '',
                    content,
                    severity,
                });
            }
        },
        globalMessageRemoved(state, { payload }) {
            const foundMessageIndex = state.messages.findIndex(stateMessage => stateMessage.id === payload.id);

            if (foundMessageIndex > -1) {
                state.messages.splice(foundMessageIndex, 1);
            }
        },
    },
});

const { actions, reducer } = globalMessagesSlice;

export { actions, initialState, reducer };
