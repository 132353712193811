import React, { FC, useMemo } from 'react';
import { styled } from '@mui/material';
import { ConsumptionUsageSummaryType } from '../../constants/Consumption';
import TotalUsage from './TotalUsage';
import ConsumptionUsage from './ConsumptionUsage';

const PREFIX = 'ConsumptionUsageSummary';
const classes = {
    usagePanel: `${PREFIX}__usage-panel`,
};

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    columnGap: '12px',
    [`& .${classes.usagePanel}`]: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '12px',
        overflowX: 'auto',
    },
});

type OwnProps = {
    consumptionUsageSummary: ConsumptionUsageSummaryType;
};

const ConsumptionUsageSummary: FC<OwnProps> = ({ consumptionUsageSummary }) => {
    const { consumptionUsages, totalCases, totalProcessed } = consumptionUsageSummary;

    const usageComponents = useMemo(
        () =>
            consumptionUsages.map(consumptionUsage => (
                <ConsumptionUsage consumptionUsage={consumptionUsage} key={consumptionUsage.name} />
            )),
        [consumptionUsages]
    );

    return (
        <Root>
            <TotalUsage totalCases={totalCases} totalProcessed={totalProcessed} />
            {consumptionUsages.length > 0 && <div className={classes.usagePanel}>{usageComponents}</div>}
        </Root>
    );
};

export default ConsumptionUsageSummary;
