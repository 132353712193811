import { InputClasses } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides.js';
import { inputOverrides as nomiInputOverrides } from '@nuix/common-ui/dist/styled-components/themes/components/input';
import colors from './colors';

export const inputOverrides: Partial<OverridesStyleRules<keyof InputClasses>> = {
    ...nomiInputOverrides,
    root: {
        ...(nomiInputOverrides.root as object),
        height: '32px',
        '&:hover, &:active': {
            borderColor: colors.primary60,
        },
        '&:hover:has(.Mui-disabled), &:active:has(.Mui-disabled)': {
            borderColor: colors.ui00,
        },
    },
};
