import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { capitalizeFirstLetter, makeI18n, NuixApplicationRoot } from '@nuix/common-ui';
import { customerPortal18nBackend } from './utils/i18n';
import App from './routing/App';
import store from './state/store';
import 'typeface-noto-sans';
import './index.css';

const i18n = makeI18n({
    initConfig: {
        backend: {
            backends: [resourcesToBackend(customerPortal18nBackend)],
        },
        debug: process.env.NODE_ENV !== 'production',
        defaultNS: 'customer-frontend_app',
        returnNull: false,
        ns: ['customer-frontend_app', 'customer-frontend_glossary'],
    },
});
i18n.services.formatter.add('capitalizeFirstLetter', capitalizeFirstLetter);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <I18nextProvider i18n={i18n}>
            <NuixApplicationRoot>
                <App />
            </NuixApplicationRoot>
        </I18nextProvider>
    </Provider>
);
