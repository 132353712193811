import gql from 'graphql-tag';

export const GET_OFFLINE_LICENCE_SUMMARY = gql`
    query GetOfflineLicenceSummary {
        getOfflineLicenceSummary {
            id
            name
            owner
        }
    }
`;

export const GET_CONSUMPTION_BY_DONGLE = gql`
    query SearchConsumptionReports(
        $size: Int!
        $pageNumber: Int!
        $pageSort: PageSortInput!
        $searchTerm: String!
        $fromDateTime: DateTime!
        $toDateTime: DateTime!
        $dongleNames: [String]
    ) {
        searchConsumptionReports(
            page: { size: $size, pageNumber: $pageNumber, pageSort: $pageSort }
            searchTerm: $searchTerm
            fromDateTime: $fromDateTime
            toDateTime: $toDateTime
            licenceModes: [OFFLINE]
            dongleNames: $dongleNames
        ) {
            number
            numberOfElements
            totalPages
            totalElements
            size
            content {
                auditType
                batchLoadId
                cap
                capSchedule
                caseName
                dongleName
                generatedAt
                id
                licenceMode
                productVersion
                totalSinceCapStart
                totals {
                    includedSize
                }
                topLevelBinarySize {
                    includedSize
                }
            }
        }
    }
`;

export const GET_CONSUMPTION_USAGE_SUMMARY_BY_DONGLE = gql`
    query GetConsumptionUsageSummary($dongleNames: [String]) {
        getConsumptionUsageSummary(dongleNames: $dongleNames) {
            totalProcessed
            totalCases
            consumptionUsages {
                convertedOfflineLicenceId
                name
                productProfile
                totalProcessed
                totalCases
                totalLeft
                totalCap
                expiry
                licenceMode
                auditType
                lastUsage {
                    totalProcessed
                    caseName
                    generatedAt
                    productVersion
                    username
                }
            }
        }
    }
`;
