import gql from 'graphql-tag';

export const GET_AUDIT_REPORT_UPLOAD_LINK = gql`
    query GetAuditReportUploadLink($fileName: String!) {
        getAuditReportUploadLink(fileName: $fileName) {
            fileUuid
            uploadUrl
        }
    }
`;

export const GET_AUDIT_REPORT_DOWNLOAD_LINK = gql`
    query GetProcessedAuditReportDownloadLink($id: Int!) {
        getProcessedAuditReportDownloadLink(id: $id)
    }
`;

export const GET_AUDIT_REPORTS = gql`
    query SearchAuditReportControlRecords(
        $size: Int!
        $pageNumber: Int!
        $pageSort: PageSortInput!
        $searchTerm: String!
        $statuses: [AuditReportControlStatus!]!
    ) {
        searchAuditReportControlRecords(
            page: { size: $size, pageNumber: $pageNumber, pageSort: $pageSort }
            searchTerm: $searchTerm
            statuses: $statuses
        ) {
            number
            numberOfElements
            totalPages
            totalElements
            size
            content {
                createdAt
                failedCount
                fileName
                fileUuid
                id
                processedCount
                status
                statusDetails
                statusDetailsCode
                totalCount
                userName
            }
        }
    }
`;

export const UPDATE_AUDIT_REPORT_UPLOAD_STATUS = gql`
    query updateAuditReportControlStatus(
        $fileUuid: String!
        $status: AuditReportControlStatus!
        $statusDetails: String!
    ) {
        updateAuditReportControlStatus(fileUuid: $fileUuid, status: $status, statusDetails: $statusDetails) {
            createdAt
            failedCount
            fileName
            fileUuid
            id
            processedAt
            processedCount
            processedSize
            size
            status
            statusDetails
            totalCount
            uploadedAt
            userName
            organisation {
                id
                companyName
            }
        }
    }
`;
