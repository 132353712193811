import { AuditType, CapSchedule } from '../constants/Consumption';

const UNITS = ['B', 'KB', 'MB', 'GB'];
const STEP = 1000;

const formatDataSize = (number, negative, precision, numberFormat, unit) => {
    const value = number.toFixed(precision);
    return `${numberFormat.format(negative ? value * -1 : value)} ${unit}`;
};

export const convertByte = (cap, numberFormat, precision = 2) => {
    let number = Number(cap);
    const negative = number < 0;
    number = negative ? number * -1 : number;
    const size = UNITS.length - 1;
    for (let i = 0; i < size; i++) {
        if (number < STEP) {
            return formatDataSize(number, negative, precision, numberFormat, UNITS[i]);
        }
        number = number / STEP;
    }

    return formatDataSize(number, negative, precision, numberFormat, UNITS[size]);
};

const isPayAsYouGoLicence = auditType => auditType === AuditType.PAYG;

export const formatProductVersion = productVersion => {
    if (productVersion) {
        const versionArray = productVersion.split('.', 3);
        return versionArray.join('.');
    }
    return '';
};

export const getOriginalDataSize = ({ topLevelBinarySize }, i18n, numberFormat) => {
    if (topLevelBinarySize?.includedSize || topLevelBinarySize?.includedSize === 0) {
        return convertByte(topLevelBinarySize.includedSize, numberFormat);
    }

    return i18n.t('consumption-utils.n-a');
};

export const getAllowedDataSize = ({ auditType, capSchedule, cap }, i18n, numberFormat) => {
    if (auditType && !isPayAsYouGoLicence(auditType)) {
        const totalCap = convertByte(cap, numberFormat, 0);

        if (capSchedule) {
            return `${totalCap} / ${CapSchedule(i18n)[capSchedule].label}`;
        }

        return totalCap;
    }
    return i18n.t('consumption-utils.n-a');
};

export const getProcessedDataSize = ({ totals }, numberFormat) => {
    if (totals?.includedSize || totals?.includedSize === 0) {
        return convertByte(totals.includedSize, numberFormat);
    }

    return '';
};

export const getTotalLeft = ({ cap = 0, totalSinceCapStart = 0, auditType }, i18n, numberFormat) => {
    if (!auditType || auditType === AuditType.PAYG) {
        return i18n.t('consumption-utils.n-a');
    }

    const outstandingCap = cap - totalSinceCapStart;
    return convertByte(outstandingCap <= 0 && auditType === AuditType.COMPOUND_CAP ? 0 : outstandingCap, numberFormat);
};
