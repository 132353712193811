import gql from 'graphql-tag';

export const GET_PG_ACTIVE_SESSIONS = gql`
    query GetAuthorizedFulfillments($size: Int!, $pageNumber: Int!, $pageSort: PageSortInput!) {
        getAuthorizedFulfillments(page: { size: $size, pageNumber: $pageNumber, pageSort: $pageSort }) {
            number
            numberOfElements
            totalPages
            totalElements
            size
            content {
                sessionToken
                quantity
                acquiredAt
                lastAlive
                localUsername
                licence {
                    id
                    displayId
                    productProfile {
                        displayId
                        skuCode
                        name
                        metricType
                    }
                }
                user {
                    id
                    username
                }
                publicIpAddress
                privateIpAddress
                machineId
            }
        }
    }
`;
