import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, SlotComponentProps, styled } from '@mui/material';
import { dayCalendarClasses } from '@mui/x-date-pickers';
import { DateRange, DateRangeCalendar, dateRangeCalendarClasses, RangePosition } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import colors from '../../theme/colors';
import CalendarDay from './CalendarDay';
import CalendarHeader from './CalendarHeader';

const PREFIX = 'Calendar';
const classes = {
    todayContainer: `${PREFIX}__today-container`,
};

const Container = styled('div')({
    [`.${classes.todayContainer}`]: {
        display: 'flex',
        justifyContent: 'center',
        padding: '8px 0 15px 0',
    },
    [`.${dateRangeCalendarClasses.monthContainer}`]: {
        display: 'flex',
        flexDirection: 'column',
    },
    [`.${dayCalendarClasses.slideTransition}`]: {
        minHeight: '164px',
        minWidth: 0,
    },
    [`.${dayCalendarClasses.root}`]: {
        fontSize: '12px',
    },
    [`.${dayCalendarClasses.header}`]: {
        columnGap: '8px',
        marginBottom: '4px',
    },
    [`.${dayCalendarClasses.weekDayLabel}`]: {
        color: colors.ui70,
        fontSize: '12px',
        height: '24px',
        width: '24px',
        margin: 0,
    },
    [`.${dayCalendarClasses.weekContainer}`]: {
        height: '24px',
        margin: 0,
    },
    [`.${dayCalendarClasses.monthContainer}`]: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        rowGap: '4px',
    },
});

type OwnProps = {
    defaultMonth?: Dayjs | null;
    lastReviewingDate?: Dayjs | null;
    onChange: (value: DateRange<Dayjs>) => void;
    onSetToday: () => void;
    rangePosition: RangePosition;
    setLastReviewingDate?: (value: Dayjs | null) => void;
    value: DateRange<Dayjs>;
};

const Calendar: FC<OwnProps> = ({
    defaultMonth = null,
    lastReviewingDate,
    onChange,
    onSetToday,
    rangePosition,
    setLastReviewingDate,
    value,
}) => {
    const { t } = useTranslation();

    return (
        <Container data-testid='calender'>
            <DateRangeCalendar
                calendars={1}
                dayOfWeekFormatter={weekday => `${weekday.format('dd')}`}
                disableAutoMonthSwitching
                disableFuture
                onChange={onChange}
                rangePosition={rangePosition}
                value={value}
                slots={{
                    calendarHeader: CalendarHeader,
                    day: CalendarDay,
                }}
                slotProps={{
                    calendarHeader: {
                        defaultMonth,
                        onSetToday: () => {},
                    } as SlotComponentProps<typeof CalendarHeader, {}, {}>,
                    day: {
                        calenderValue: value,
                        lastReviewingDate,
                        rangePosition,
                        setLastReviewingDate,
                    } as SlotComponentProps<typeof CalendarDay, {}, {}>,
                }}
            />
            <div className={classes.todayContainer}>
                <Button variant='link' onClick={onSetToday}>
                    {t('calendar.button.today')}
                </Button>
            </div>
        </Container>
    );
};

export default Calendar;
