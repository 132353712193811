import React from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Button, IconButton } from '@mui/material';
import {
    MuiDownloadIcon,
    MuiInfoIcon,
    MuiCheckCircleIcon,
    NomiErrorSquareIcon,
    MuiWarningIcon,
} from '@nuix/nomi-icons';
import InProgressCircle from '../components/in-progress-circle/InProgressCircle';
import { tableClasses } from '../components/table/Table';
import colors from '../theme/colors';
import { getDisplayDateTime } from '../utils/date-utils';
import { Direction, PageSortType } from './Pagination';

export const DEFAULT_PAGE_SORT: PageSortType = {
    fieldName: 'createdAt',
    direction: Direction.DESC,
};

export const StatusDetailCodeMessage = {
    PROCESSING_FAILED: i18n =>
        i18n.t('audit-report-verification.status-detail-code-message.this-report-failed-to-be-processed'),
    NOT_A_SINGLE_SUCCESSFUL_AUDIT_FILE: i18n =>
        i18n.t(
            'audit-report-verification.status-detail-code-message.none-of-the-audit-files-were-processed-successfully'
        ),
};

export const AuditReportStatus = {
    NEW: {
        displayName: i18n => i18n.t('audit-report-verification.audit-report-status.new-field.display-name.verifying'),
        icon: <InProgressCircle />,
        needRefresh: true,
        tooltip: i18n =>
            i18n.t('audit-report-verification.audit-report-status.new-field.tooltip.this-report-is-uploading'),
        value: 'NEW',
    },
    UPLOADED: {
        displayName: i18n =>
            i18n.t('audit-report-verification.audit-report-status.uploaded-field.display-name.verifying'),
        icon: <InProgressCircle />,
        needRefresh: true,
        tooltip: i18n =>
            i18n.t('audit-report-verification.audit-report-status.uploaded-field.tooltip.this-report-is-uploaded'),
        value: 'UPLOADED',
    },
    UPLOAD_FAILED: {
        displayName: i18n =>
            i18n.t('audit-report-verification.audit-report-status.upload-failed-field.display-name.failed'),
        icon: <NomiErrorSquareIcon fill={colors.error80} />,
        needRefresh: false,
        severity: 'error',
        tooltip: i18n =>
            i18n.t(
                'audit-report-verification.audit-report-status.upload-failed-field.tooltip.this-report-failed-to-upload'
            ),
        value: 'UPLOAD_FAILED',
    },
    PROCESSING: {
        displayName: i18n =>
            i18n.t('audit-report-verification.audit-report-status.processing-field.display-name.verifying'),
        icon: <InProgressCircle />,
        needRefresh: true,
        tooltip: i18n =>
            i18n.t('audit-report-verification.audit-report-status.processing-field.tooltip.this-report-is-verifying'),
        value: 'PROCESSING',
    },
    PROCESSED: {
        displayName: i18n =>
            i18n.t('audit-report-verification.audit-report-status.processed-field.display-name.verified'),
        icon: <MuiCheckCircleIcon fill={colors.success80} />,
        needRefresh: false,
        severity: 'success',
        tooltip: i18n =>
            i18n.t('audit-report-verification.audit-report-status.processed-field.tooltip.this-report-is-verified'),
        value: 'PROCESSED',
    },
    PROCESS_FAILED: {
        displayName: i18n =>
            i18n.t('audit-report-verification.audit-report-status.process-failed-field.display-name.failed'),
        icon: <NomiErrorSquareIcon fill={colors.error80} />,
        needRefresh: false,
        severity: 'error',
        tooltip: i18n =>
            i18n.t(
                'audit-report-verification.audit-report-status.process-failed-field.tooltip.this-report-failed-to-be-processed'
            ),
        value: 'PROCESS_FAILED',
    },
    PROCESSED_PARTIALLY: {
        displayName: i18n =>
            i18n.t(
                'audit-report-verification.audit-report-status.verified-with-errors-field.display-name.verified-with-errors'
            ),
        icon: <MuiWarningIcon fill={colors.warn80} />,
        needRefresh: false,
        severity: 'warning',
        tooltip: i18n =>
            i18n.t(
                'audit-report-verification.audit-report-status.verified-with-errors-field.tooltip.this-report-is-verified-with-errors'
            ),
        value: 'PROCESSED_PARTIALLY',
    },
};

export const AuditReportStatusOptions = i18n => ({
    FAILED: {
        beValues: [AuditReportStatus.UPLOAD_FAILED.value, AuditReportStatus.PROCESS_FAILED.value].toString(),
        icon: <NomiErrorSquareIcon fill={colors.error80} />,
        label: i18n.t('audit-report-verification.audit-report-status-options.failed-field.label.failed'),
        value: 'FAILED',
    },
    PROCESSED_PARTIALLY: {
        beValues: [AuditReportStatus.PROCESSED_PARTIALLY.value].toString(),
        icon: <MuiWarningIcon fill={colors.warn80} />,
        label: i18n.t(
            'audit-report-verification.audit-report-status-options.verified-with-errors-field.label.verified-with-errors'
        ),
        value: 'PROCESSED_PARTIALLY',
    },
    VERIFIED: {
        beValues: AuditReportStatus.PROCESSED.value,
        icon: <MuiCheckCircleIcon fill={colors.success80} />,
        label: i18n.t('audit-report-verification.audit-report-status-options.verified-field.label.verified'),
        value: 'VERIFIED',
    },
    VERIFYING: {
        beValues: [
            AuditReportStatus.NEW.value,
            AuditReportStatus.UPLOADED.value,
            AuditReportStatus.PROCESSING.value,
        ].toString(),
        icon: <InProgressCircle />,
        label: i18n.t('audit-report-verification.audit-report-status-options.verifying-field.label.verifying'),
        value: 'VERIFYING',
    },
});

export const getDisplayStatus = (reportStatus: string, i18n, disableRefresh = false, refetch?) => {
    const status = AuditReportStatus[reportStatus];
    return (
        <div>
            <div title={status.tooltip(i18n)} className={tableClasses.cellIconWithValue}>
                {status.icon}
            </div>
            {status.displayName(i18n)}
            {status.needRefresh && refetch && (
                <Button variant='link' disabled={disableRefresh} onClick={refetch}>
                    {i18n.t('audit-report-verification.get-display-status.refresh')}
                </Button>
            )}
        </div>
    );
};

const renderStatusCell = (params: GridRenderCellParams<any, string>, i18n, disableRefresh, refetch) => {
    if (!params.formattedValue) {
        return;
    }
    return getDisplayStatus(params.formattedValue, i18n, disableRefresh, refetch);
};

const renderDownloadCell = (params: GridRenderCellParams<AuditReportVerificationRow>, i18n, downloadReport) => {
    const { row } = params;
    const { status } = row;
    const disabled = !(
        status === AuditReportStatus.PROCESSED.value || status === AuditReportStatus.PROCESSED_PARTIALLY.value
    );
    return (
        <div title={i18n.t('audit-report-verification.render-download-cell.download-processed-report')}>
            <IconButton onClick={() => downloadReport(row)} disabled={disabled} size='small'>
                <MuiDownloadIcon />
            </IconButton>
        </div>
    );
};

const renderDetailCell = (params: GridRenderCellParams<AuditReportVerificationRow>, i18n, showDetail) => {
    const { row } = params;
    const { status } = row;
    const disabled =
        status === AuditReportStatus.NEW.value ||
        status === AuditReportStatus.UPLOADED.value ||
        status === AuditReportStatus.PROCESSING.value;
    return (
        <div title={i18n.t('audit-report-verification.render-detail-cell.report-information')}>
            <IconButton onClick={() => showDetail(row)} disabled={disabled} size='small'>
                <MuiInfoIcon />
            </IconButton>
        </div>
    );
};

export type AuditReportVerificationRow = {
    createdAt: string;
    failedCount: number | null;
    fileName: string;
    fileUuid: string;
    id: number;
    processedAt: string | null;
    processedCount: number | null;
    processedSize: number | null;
    size: number | null;
    status: string;
    statusDetails: string | null;
    statusDetailsCode: string | null;
    totalCount: number | null;
    uploadedAt: string | null;
    userName: string;
};

export const getAuditReportVerificationColumns = (
    i18n,
    showDetail,
    downloadReport,
    disableRefresh,
    refetch
): GridColDef<AuditReportVerificationRow>[] => [
    {
        field: 'fileName',
        flex: 1.5,
        headerName: i18n.t(
            'audit-report-verification.get-audit-report-verification-columns.file-name-column.header-name.file-name'
        ),
        resizable: true,
    },
    {
        field: 'userName',
        flex: 1,
        headerName: i18n.t(
            'audit-report-verification.get-audit-report-verification-columns.creator-column.header-name.creator'
        ),
        resizable: true,
    },
    {
        field: 'createdAt',
        flex: 1,
        headerName: i18n.t(
            'audit-report-verification.get-audit-report-verification-columns.date-column.header-name.date'
        ),
        type: 'dateTime',
        resizable: true,
        valueFormatter: value => getDisplayDateTime(value),
    },
    {
        field: 'status',
        flex: 1,
        headerName: i18n.t(
            'audit-report-verification.get-audit-report-verification-columns.status-column.header-name.status'
        ),
        sortable: false,
        resizable: true,
        renderCell: params => renderStatusCell(params, i18n, disableRefresh, refetch),
    },
    {
        field: 'download-button',
        headerName: '',
        sortable: false,
        width: 40,
        renderCell: params => renderDownloadCell(params, i18n, downloadReport),
    },
    {
        field: 'id',
        headerName: '',
        sortable: false,
        width: 40,
        renderCell: params => renderDetailCell(params, i18n, showDetail),
    },
];
