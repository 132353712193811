import colors from './colors';

const MuiCheckbox = {
    root: {
        height: 24,
        width: 24,
        '& svg': {
            fontSize: 16,
        },
    },
    colorSecondary: {
        '&$checked': {
            color: colors.primary50,
        },
    },
};

export default MuiCheckbox;
