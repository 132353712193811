import { buttonClasses, ButtonClasses, ButtonProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides.js';
import { applyOpacityToHex } from '@nuix/common-ui';
import colors from './colors';

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        action: true;
        link: true;
    }
}

const buttonOverrides: Partial<OverridesStyleRules<keyof ButtonClasses>> = {
    root: {
        textTransform: 'none',
        padding: '6px 20px',
        height: '32px',
        lineHeight: '20px',
        fontWeight: 400,
        fontSize: '14px',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
        },
    },
};

const buttonDefaultProps: Partial<ButtonProps> = {
    disableElevation: true,
};

interface Variant {
    props: Partial<ButtonProps>;
    style: any;
}

const buttonVariants: Variant[] = [
    {
        props: { variant: 'contained' },
        style: {
            backgroundColor: colors.primary50,
            color: colors.ui00,
            fontWeight: 600,
            '&:hover': {
                backgroundColor: colors.primary70,
            },
            '&:active': {
                backgroundColor: colors.primary80,
            },
            '&:focus': {
                border: `3px solid ${colors.primary30}`,
                backgroundColor: colors.primary70,
                padding: '3px 17px',
            },
            '&:disabled': {
                backgroundColor: colors.ui50,
                color: colors.ui00,
            },
        },
    },
    {
        props: { variant: 'action' },
        style: {
            color: colors.secondary50,
            fontWeight: 400,
            padding: '6px 8px',
            '&:hover': {
                backgroundColor: applyOpacityToHex(colors.secondary50, 0.08),
                color: colors.primary70,
                svg: {
                    color: colors.primary70,
                },
            },
            '&:active': {
                backgroundColor: applyOpacityToHex(colors.secondary50, 0.16),
                color: colors.primary70,
                svg: {
                    color: colors.primary70,
                },
            },
            '&:focus': {
                border: `3px solid ${applyOpacityToHex(colors.primary60, 0.24)}`,
                backgroundColor: applyOpacityToHex(colors.secondary50, 0.08),
                padding: '3px 5px',
                color: colors.primary70,
                svg: {
                    color: colors.primary70,
                },
            },
            '&.Mui-disabled': {
                color: colors.ui50,
                svg: {
                    color: colors.ui50,
                },
            },
            svg: {
                color: colors.secondary50,
                height: '16px',
                width: '16px',
            },
            [`.${buttonClasses.icon}`]: {
                marginLeft: 0,
            },
        },
    },
    {
        props: { variant: 'link' },
        style: {
            color: colors.primary60,
            '&:hover': {
                backgroundColor: applyOpacityToHex(colors.secondary50, 0.08),
                color: colors.primary70,
            },
            '&:active': {
                backgroundColor: applyOpacityToHex(colors.secondary50, 0.16),
                color: colors.primary70,
            },
            '&:focus': {
                border: `3px solid ${applyOpacityToHex(colors.primary60, 0.24)}`,
                backgroundColor: applyOpacityToHex(colors.secondary50, 0.08),
                color: colors.primary70,
                padding: '3px 17px',
            },
            '&:disabled': {
                color: colors.ui50,
            },
        },
    },
];

export { buttonOverrides, buttonDefaultProps, buttonVariants };
