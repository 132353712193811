import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, styled } from '@mui/material';
import { MuiAvTimerIcon } from '@nuix/nomi-icons';
import { getModuleTabIdByLink } from '../../module/module-utils';
import { RootState } from '../../state/store';
import { actions as moduleSelectionActions } from '../../state/module-selection/moduleSelection-slice';
import colors from '../../theme/colors';
import ActiveSessions from '../active-sessions/ActiveSessions';
import AuditReportVerification from '../audit-report-verification/AuditReportVerification';
import ConsumptionByDongle from '../consumption-by-dongle/ConsumptionByDongle';
import GlobalMessages from '../global-messages/globalMessages';
import MainAppBar from '../main-app-bar/MainAppBar';
import Modules from '../../module/modules';
import SideDrawer from '../side-drawer/SideDrawer';
import { getTimezone } from '../../utils/date-utils';

const PREFIX = 'MainBox';
const classes = {
    header: `${PREFIX}__header`,
    headerRight: `${PREFIX}__header-right`,
    content: `${PREFIX}__content`,
    contentTitle: `${PREFIX}__content-title`,
    filters: `${PREFIX}__filters`,
    main: `${PREFIX}__main`,
    tableToolbar: `${PREFIX}__table-toolbar`,
    timeZoneIcon: `${PREFIX}__time-zone-icon`,
    timeZoneValue: `${PREFIX}__time-zone-value`,
};
const Root = styled(Box)({
    color: colors.ui80,
    [`& .${classes.header}`]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px 20px 8px 16px',
    },
    [`& .${classes.headerRight}`]: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '4px',
        fontSize: '16px',
    },
    [`& .${classes.content}`]: {
        backgroundColor: colors.ui20,
        padding: '12px 20px 0 20px',
        height: 'calc(100% - 81px)',
        display: 'flex',
        flexDirection: 'column',
    },
    [`& .${classes.contentTitle}`]: {
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '24px',
    },
    [`& .${classes.main}`]: {
        minWidth: 0,
    },
    [`& .${classes.filters}`]: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '8px',
    },
    [`& .${classes.tableToolbar}`]: {
        backgroundColor: colors.ui00,
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'space-between',
        margin: '8px 0px',
        padding: '8px 16px',
        height: '48px',
    },
    [`.${classes.timeZoneIcon}`]: {
        fill: colors.secondary50,
        height: '20px',
        width: '20px',
    },
    [`.${classes.timeZoneValue}`]: {
        fontWeight: 600,
    },
});

const MainBox: FC = () => {
    const { i18n, t } = useTranslation();
    const dispatch = useDispatch();
    const selectedModuleId = useSelector((state: RootState) => state.moduleSelection.selectedModuleId);
    const selectedModule = Modules[selectedModuleId];

    const location = useLocation();

    useEffect(() => {
        const { moduleId, tabId } = getModuleTabIdByLink(location.pathname);
        if (moduleId && tabId) {
            dispatch(moduleSelectionActions.selectModule({ moduleId }));
            dispatch(moduleSelectionActions.selectTab({ moduleId, tabId }));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Root display='flex' flexDirection='row' data-testid='main-box'>
            <SideDrawer />
            <Box flex={1} display='flex' flexDirection='column' className={classes.main}>
                <div className={classes.header}>
                    <Typography className={classes.contentTitle}>{selectedModule.title(i18n)}</Typography>
                    <div className={classes.headerRight}>
                        <MuiAvTimerIcon className={classes.timeZoneIcon} />
                        <div>{t('main-box.time-zone-label.time-zone')}</div>
                        <div className={classes.timeZoneValue}>{getTimezone()}</div>
                    </div>
                </div>
                <MainAppBar />
                <Box flexGrow={1} className={classes.content}>
                    <Routes>
                        <Route path='/licensing/activeSessions' element={<ActiveSessions />} />
                        <Route path='/dataUsage/auditReportVerification' element={<AuditReportVerification />} />
                        <Route path='/dataUsage/consumptionByDongle' element={<ConsumptionByDongle />} />
                        <Route path='*' element={<Navigate to='/licensing/activeSessions' />} />
                    </Routes>
                </Box>
            </Box>
            <GlobalMessages />
        </Root>
    );
};

export default MainBox;
export { classes as mainBoxClasses };
