import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContentText } from '@mui/material';
import Modal from '../../components/modal/Modal';

type OwnProps = {
    message: string;
    title: string;
};

const ModalDialog: FC<OwnProps> = ({ message, title }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(true);

    return open ? (
        <Modal closeButtonLabel={t('modal-dialog.button.close')} onClose={() => setOpen(false)} title={title}>
            <DialogContentText>{message}</DialogContentText>
        </Modal>
    ) : (
        <></>
    );
};

export default ModalDialog;
