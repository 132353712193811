import React, { FC } from 'react';
import { styled } from '@mui/material';
import { applyOpacityToHex } from '@nuix/common-ui';
import colors from '../../theme/colors';

const PREFIX = 'Avatar';
const classes = {
    base: PREFIX,
    flareTop: `${PREFIX}__flare--top`,
    flareBottom: `${PREFIX}__flare--bottom`,
    initials: `${PREFIX}__initials`,
};

const lensFlare = offset => ({
    background: `linear-gradient(0.1turn, ${applyOpacityToHex(colors.secondary50, 0.2)} 10%, ${applyOpacityToHex(colors.ui00, 0.2)})`,
    position: 'absolute',
    borderRadius: '50%',
    height: '20px',
    width: '20px',
    top: offset,
    left: offset,
});

const Container = styled('div')({
    backgroundColor: colors.secondary50,
    border: `1px solid ${colors.ui20}`,
    borderRadius: '50%',
    height: '24px',
    width: '24px',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'relative',
    [`.${classes.flareTop}`]: {
        ...lensFlare('25%'),
    },
    [`.${classes.flareBottom}`]: {
        ...lensFlare('-25%'),
    },
    [`.${classes.initials}`]: {
        color: colors.ui00,
        fontSize: '10px',
        fontWeight: 700,
    },
    '&:active': {
        backgroundColor: colors.ui70,
    },
    '&:hover': {
        [`.${classes.flareTop}, .${classes.flareBottom}`]: {
            background: `linear-gradient(0.1turn, ${applyOpacityToHex(colors.secondary50, 0.4)} 10%, ${applyOpacityToHex(colors.ui00, 0.4)})`,
        },
    },
    '&:focus': {
        border: `2px solid ${colors.primary30}`,
    },
});

type OwnProps = {
    initials: string;
};

const Avatar: FC<OwnProps> = ({ initials }) => (
    <Container className={classes.base}>
        <div className={classes.flareTop} />
        <div className={classes.flareBottom} />
        <div className={classes.initials}>{initials && initials.toUpperCase()}</div>
    </Container>
);

export default Avatar;
export { classes as avatarClasses };
