import { SESSION_STORAGE } from './session-constants';
import { jwtDecode, JwtPayload } from 'jwt-decode';

export type UserType = {
    userName?: any;
    userId?: any;
    email?: any;
    expiresIn?: number;
    scope?: any;
};

interface IJwtPayload extends JwtPayload {
    user_name: string;
    user_id: string;
    email: string;
    scope: string[];
}

/**
 * Gets the JWT token.
 *
 * @returns {string}
 */
const getToken = (): string | null => sessionStorage.getItem(SESSION_STORAGE.TOKEN);

/**
 * Sets the JWT token.
 *
 * @param token
 */
const setToken = (token: string): void => {
    sessionStorage.setItem(SESSION_STORAGE.TOKEN, token);
};

/**
 * Clears the JWT token.
 */
const clearToken = (): void => {
    sessionStorage.removeItem(SESSION_STORAGE.TOKEN);
};

/**
 * Gets the last location.
 *
 * @returns {string}
 */
const getLocation = (): string | null => sessionStorage.getItem(SESSION_STORAGE.ORIGINAL_HREF);

/**
 * Sets the last location.
 *
 * @param location
 */
const setLocation = (location: string): void => {
    sessionStorage.setItem(SESSION_STORAGE.ORIGINAL_HREF, location);
};

/**
 * Has OfflineLicences?
 *
 * @returns {string}
 */
const hasOfflineLicences = (): boolean => sessionStorage.getItem(SESSION_STORAGE.HAS_OFFLINE_LICENCES) === 'true';

/**
 * Sets the hasOfflineLicences.
 *
 * @param hasOfflineLicences
 */
const setHasOfflineLicences = (hasOfflineLicences: boolean): void => {
    sessionStorage.setItem(SESSION_STORAGE.HAS_OFFLINE_LICENCES, hasOfflineLicences.toString());
};

/**
 * Display Machine Details In Active Sessions?
 *
 * @returns {string}
 */
const displayMachineDetailsInActiveSessions = (): boolean =>
    sessionStorage.getItem(SESSION_STORAGE.DISPLAY_MACHINE_DETAILS_IN_ACTIVE_SESSIONS) === 'true';

/**
 * Sets the Display Machine Details In Active Sessions.
 *
 * @param displayMachineDetailsInActiveSessions
 */
const setDisplayMachineDetailsInActiveSessions = (displayMachineDetailsInActiveSessions: boolean): void => {
    sessionStorage.setItem(
        SESSION_STORAGE.DISPLAY_MACHINE_DETAILS_IN_ACTIVE_SESSIONS,
        displayMachineDetailsInActiveSessions.toString()
    );
};

/**
 * Gets the logged in user.
 *
 * @returns {{}|{expiresIn: *, userName: *, userId: *, email: *}}
 */
const getLoggedInUser = (): UserType => {
    const token = getToken();
    return token ? _parseAccessToken(token) : {};
};

/**
 * Is user having the permission?
 *
 * @returns true if user has the permission. otherwise false.
 */
const hasPermission = (permission: string): boolean => {
    const token = getToken();
    return token ? _parseAccessToken(token).scope.includes(permission) : false;
};

/**
 * Clears the session.
 */
const destroy = (): void => {
    sessionStorage.clear();
};

/**
 * Parse the access token.
 *
 * @param token
 * @returns {{expiresIn: *, userName: *, userId: *, email: *, scope: *}|{}}
 * @private
 */
const _parseAccessToken = (token: string): UserType => {
    if (!token) return {};

    const { user_name: userName, user_id: userId, email, exp: expiresIn, scope }: IJwtPayload = jwtDecode(token);
    return {
        userName,
        userId,
        email,
        expiresIn,
        scope,
    };
};

const Session = {
    getToken,
    setToken,
    clearToken,
    getLocation,
    setLocation,
    hasOfflineLicences,
    setHasOfflineLicences,
    displayMachineDetailsInActiveSessions,
    setDisplayMachineDetailsInActiveSessions,
    getLoggedInUser,
    hasPermission,
    destroy,
};

export default Session;
