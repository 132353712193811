import { createTheme } from '@mui/material';
import { makeI18nNomiTheme } from '@nuix/common-ui';
import { alertDefaultProps } from '@nuix/common-ui/dist/styled-components/themes/components/alert';
import { buttonDefaultProps, buttonOverrides, buttonVariants } from './button';
import { alertOverrides } from './alert';
import { autocompleteOverrides } from './autocomplete';
import { iconButtonOverrides, iconButtonVariants } from './iconButton';
import { inputOverrides } from './inputs';
import MuiAppBar from './MuiAppBar';
import MuiToolbar from './MuiToolbar';
import MuiTypography from './MuiTypography';
import MuiCheckbox from './MuiCheckbox';
import MuiFormLabel from './MuiFormLabel';
import MuiStepLabel from './MuiStepLabel';
import MuiTab from './MuiTab';
import MuiTabs from './MuiTabs';
import colors from './colors';

// done like this temporarily until the entire application is nomified (this includes the theme overrides)
export const getNuixTheme = language => {
    const nomiTheme = makeI18nNomiTheme(language);
    return createTheme(
        {
            ...nomiTheme,
            palette: {
                ...nomiTheme.palette,
                background: {
                    default: colors.ui20,
                },
            },
            transitions: {
                create: () => 'none',
            },
            components: {
                ...nomiTheme.components,
                MuiAlert: {
                    styleOverrides: alertOverrides,
                    defaultProps: alertDefaultProps,
                },
                MuiAppBar: {
                    styleOverrides: MuiAppBar,
                },
                MuiAutocomplete: {
                    styleOverrides: autocompleteOverrides,
                },
                MuiButton: {
                    styleOverrides: buttonOverrides,
                    defaultProps: buttonDefaultProps,
                    variants: buttonVariants,
                },
                MuiCheckbox: {
                    styleOverrides: MuiCheckbox,
                },
                MuiFormLabel: {
                    styleOverrides: MuiFormLabel,
                },
                MuiIconButton: {
                    styleOverrides: iconButtonOverrides,
                    variants: iconButtonVariants,
                },
                MuiInput: {
                    styleOverrides: inputOverrides,
                },
                MuiStepLabel: {
                    styleOverrides: MuiStepLabel,
                },
                MuiTab: {
                    styleOverrides: MuiTab,
                },
                MuiTabs: {
                    styleOverrides: MuiTabs,
                },
                MuiToolbar: {
                    styleOverrides: MuiToolbar,
                },
                MuiTypography: {
                    styleOverrides: MuiTypography,
                },
            },
        },
        language
    );
};
