import { IconButtonClasses, IconButtonProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides.js';
import colors from './colors';
import { applyOpacityToHex } from '@nuix/common-ui';

export const iconButtonOverrides: Partial<OverridesStyleRules<keyof IconButtonClasses>> = {
    root: {
        padding: '4px',
        borderRadius: '4px',
        color: colors.secondary50,
        '&:hover': {
            backgroundColor: applyOpacityToHex(colors.primary60, 0.08),
            color: colors.primary70,
        },
        '&:active': {
            backgroundColor: applyOpacityToHex(colors.primary60, 0.16),
            color: colors.primary70,
        },
        '&:focus': {
            border: `3px solid ${applyOpacityToHex(colors.primary60, 0.24)}`,
            color: colors.primary70,
            padding: '1px!important',
        },
        '&:disabled, &.Mui-disabled': {
            color: colors.ui50,
        },
    },
};

interface Variant {
    props: Partial<IconButtonProps>;
    style: any;
}

export const iconButtonVariants: Variant[] = [
    {
        props: { edge: 'end' },
        style: {
            borderRadius: '50%',
            '&:hover, &:active, &:focus': {
                color: colors.secondary50,
            },
        },
    },
    {
        props: { size: 'small' },
        style: {
            svg: {
                height: '16px',
                width: '16px',
            },
        },
    },
];
