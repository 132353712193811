import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
    styled,
    listItemIconClasses,
    listItemTextClasses,
    ListItem as MuiListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import createIcon, { Icon } from '../../assets/asset-utils';
import colors from '../../theme/colors';

const ListItem = styled(MuiListItem)({
    padding: '8px 0',
    [`.${listItemIconClasses.root}`]: {
        minWidth: '44px',
        paddingLeft: '5px',
    },
    [`.${listItemTextClasses.primary}`]: {
        fontSize: '16px',
        fontWeight: 500,
    },
    svg: {
        height: '33px',
        width: '33px',
    },
});

const SideDrawerTitle: FC = () => {
    const { t } = useTranslation();

    return (
        <ListItem key='nuix-licensing'>
            <ListItemIcon>{createIcon({ id: Icon.NuixNeo, color: colors.ui00 })}</ListItemIcon>
            <ListItemText primary={t('side-drawer-title.title.nuix-licensing')} />
        </ListItem>
    );
};

export default SideDrawerTitle;
