import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
    AuditReportStatus,
    AuditReportStatusOptions,
    DEFAULT_PAGE_SORT,
    getAuditReportVerificationColumns,
} from '../../constants/AuditReportVerification';
import FilterWithSelectAll from '../../components/filter-with-select-all/FilterWithSelectAll';
import SearchInput from '../../components/search-input/SearchInput';
import Table from '../../components/table/Table';
import { GET_AUDIT_REPORT_DOWNLOAD_LINK, GET_AUDIT_REPORTS } from '../../queries/auditReport-query';
import Session from '../../session/session';
import { RootState } from '../../state/store';
import { actions } from '../../state/audit-report-verification/auditReportVerification-slice';
import { handleError } from '../../utils/error-utils';
import LoadingOverlay from '../loading-overlay/LoadingOverlay';
import { mainBoxClasses } from '../main-box/MainBox';
import AuditReportUploadModal from './AuditReportUploadModal';
import AuditReportVerificationDetailModal from './AuditReportVerificationDetailModal';
import { downloadFile } from '../../utils/fetch-utils';
import { getDateTimeForFileName } from '../../utils/date-utils';

const AuditReportVerification: FC = () => {
    const { i18n, t } = useTranslation();

    const statusOptionMap = useMemo(() => AuditReportStatusOptions(i18n), [i18n]);
    const statusOptions = useMemo(() => Object.values(statusOptionMap), [statusOptionMap]);
    const defaultStatusFilters = useMemo(() => Object.keys(statusOptionMap), [statusOptionMap]);

    const [currentRow, setCurrentRow] = useState(null);
    const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [statusFilters, setStatusFilters] = useState(defaultStatusFilters);
    const [statusBeFilters, setStatusBeFilters] = useState(Object.keys(AuditReportStatus).sort());
    const [firstLoad, setFirstLoad] = useState(true);
    const [disableRefresh, setDisableRefresh] = useState(true);

    const [getAuditVerificationReportLink] = useLazyQuery(GET_AUDIT_REPORT_DOWNLOAD_LINK);

    const dispatch = useDispatch();
    const pageSize = useSelector((state: RootState) => state.auditReportVerification.pageSize);
    const pageSort = useSelector((state: RootState) => state.auditReportVerification.pageSort);

    useEffect(() => {
        const beFilters = statusFilters.map(filter => statusOptionMap[filter].beValues);
        setStatusBeFilters(beFilters?.length ? beFilters.toString().split(',').sort() : []);
    }, [statusFilters, statusOptionMap, setStatusFilters]);

    const handlePageSizeChanged = useCallback(
        value => {
            setPageNumber(0);
            dispatch(actions.updatePageSize(value));
        },
        [dispatch, setPageNumber]
    );

    const handlePageSortChanged = useCallback(
        value => {
            setPageNumber(0);
            dispatch(actions.updatePageSort(value));
        },
        [dispatch, setPageNumber]
    );

    const resetPage = useCallback(() => {
        setPageNumber(0);
        setSearchTerm('');
        setStatusFilters(defaultStatusFilters);
        dispatch(actions.resetPageSort());
    }, [dispatch, defaultStatusFilters, setPageNumber, setSearchTerm, setStatusFilters]);

    const showDetail = row => {
        setCurrentRow(row);
        setIsDetailModalOpen(true);
    };

    const { data, loading, error, refetch } = useQuery(GET_AUDIT_REPORTS, {
        variables: { size: pageSize, pageNumber, pageSort, searchTerm, statuses: statusBeFilters },
        onCompleted: data => {
            setDisableRefresh(true);
            setTimeout(() => setDisableRefresh(false), 10000);
        },
    });

    const reloadData = useCallback(() => {
        setDisableRefresh(true);
        refetch();
        setTimeout(() => setDisableRefresh(false), 10000);
    }, [refetch]);

    const downloadReport = useCallback(
        async row => {
            const { data, error } = await getAuditVerificationReportLink({
                variables: { id: row?.id },
            });

            if (error) {
                handleError(error, i18n);
                if (isDetailModalOpen) setIsDetailModalOpen(false);
                return;
            }

            const downloadFileName =
                row?.fileName?.substring(0, row?.fileName?.lastIndexOf('.')) +
                '-processed-' +
                getDateTimeForFileName(row?.createdAt) +
                '.zip';

            const additionalParams = {
                isPresignedURLDownload: true,
                downloadFileName,
            };
            try {
                await downloadFile(data.getProcessedAuditReportDownloadLink, {}, {}, additionalParams);
            } catch (error) {
                handleError(error, i18n);
            } finally {
                setIsDetailModalOpen(false);
            }
        },
        [getAuditVerificationReportLink, i18n, isDetailModalOpen]
    );

    const columns = useMemo(
        () => getAuditReportVerificationColumns(i18n, showDetail, downloadReport, disableRefresh, reloadData),
        [i18n, downloadReport, disableRefresh, reloadData]
    );

    const afterUploadReport = useCallback(() => {
        setIsUploadModalOpen(false);
        setTimeout(() => {
            if (
                !Object.is(pageSort, DEFAULT_PAGE_SORT) ||
                pageNumber !== 0 ||
                searchTerm ||
                statusFilters !== defaultStatusFilters
            ) {
                resetPage();
            } else {
                reloadData();
            }
        }, 500);
    }, [
        defaultStatusFilters,
        pageNumber,
        pageSort,
        searchTerm,
        statusFilters,
        reloadData,
        resetPage,
        setIsUploadModalOpen,
    ]);

    if (loading && firstLoad) {
        setFirstLoad(false);
        return <LoadingOverlay />;
    }

    if (error) {
        return handleError(error, i18n);
    }

    return (
        <>
            <div className={mainBoxClasses.tableToolbar}>
                <Button
                    variant='contained'
                    onClick={() => setIsUploadModalOpen(true)}
                    disabled={!Session.hasPermission('cls.p.off.audit_rep.upload_own_org')}
                >
                    {t('audit-report-verification.button.upload-audit-report')}
                </Button>
                {isUploadModalOpen && (
                    <AuditReportUploadModal onClose={() => setIsUploadModalOpen(false)} onUpload={afterUploadReport} />
                )}
                <div className={mainBoxClasses.filters}>
                    <FilterWithSelectAll
                        width='225px'
                        filters={statusFilters}
                        onFilterChanges={setStatusFilters}
                        options={statusOptions}
                        selectAllLabel={t('audit-report-verification.filter.status-field.all-statuses')}
                    />
                    <SearchInput
                        onChange={e => setSearchTerm(e.target.value)}
                        onButtonClick={reloadData}
                        value={searchTerm}
                    />
                </div>
            </div>
            <Table
                columns={columns}
                onPageNumberChanged={setPageNumber}
                onPageSizeChanged={handlePageSizeChanged}
                onPageSortChange={handlePageSortChanged}
                pageNumber={pageNumber}
                pageSize={pageSize}
                pageSort={pageSort}
                result={data?.searchAuditReportControlRecords}
            />
            {isDetailModalOpen && currentRow && (
                <AuditReportVerificationDetailModal
                    onClose={() => setIsDetailModalOpen(false)}
                    onDownload={() => downloadReport(currentRow)}
                    row={currentRow}
                />
            )}
        </>
    );
};

export default AuditReportVerification;
