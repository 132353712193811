import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { i18n } from '@nuix/common-ui';
import config from '../../configs/config';
import { DEFAULT_PAGE_SORT } from '../../constants/ActiveSession';
import { DEFAULT_PAGE_SIZE, PageSortType } from '../../constants/Pagination';
import { actions as globalMessagesActions } from '../global-messages/globalMessages-slice';
import { httpDelete } from '../../utils/fetch-utils';

type ActiveSessionsState = Readonly<{
    pageSize: number;
    pageSort: PageSortType;
    releasing: boolean;
}>;

const initialState: ActiveSessionsState = {
    pageSize: DEFAULT_PAGE_SIZE,
    pageSort: DEFAULT_PAGE_SORT,
    releasing: false,
};

const releaseLicense = createAsyncThunk<void, { formattedUsername: string; sessionToken: string }, { dispatch: any }>(
    'activeSessions/releaseLicense',
    async ({ formattedUsername, sessionToken }, { dispatch, rejectWithValue }) => {
        try {
            await httpDelete(`${config.apis.licenceServer}/licence/fulfillment/${sessionToken}`);
            dispatch(
                globalMessagesActions.globalMessageAdded({
                    severity: 'success',
                    content: i18n.t('active-session-slice.release-license-result.success.license-released', {
                        username: formattedUsername,
                    }),
                })
            );
        } catch (error) {
            dispatch(
                globalMessagesActions.globalMessageAdded({
                    severity: 'error',
                    title: i18n.t('active-session-slice.release-license-result.failure.release-failed'),
                    content: error?.message || '',
                })
            );
            return rejectWithValue(undefined);
        }
    }
);

const activeSessionsSlice = createSlice({
    name: 'activeSessions',
    initialState,
    reducers: {
        resetPageSort(state) {
            state.pageSort = DEFAULT_PAGE_SORT;
        },
        updatePageSize(state, { payload }) {
            state.pageSize = payload;
        },
        updatePageSort(state, { payload }) {
            state.pageSort = payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(releaseLicense.pending, state => {
                state.releasing = true;
            })
            .addCase(releaseLicense.fulfilled, state => {
                state.releasing = false;
            })
            .addCase(releaseLicense.rejected, state => {
                state.releasing = false;
            });
    },
});

const { actions, reducer } = activeSessionsSlice;
const combinedActions = {
    ...actions,
    releaseLicense,
};

export { combinedActions as actions, initialState, reducer };
