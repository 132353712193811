/* eslint-disable no-unused-vars */
import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import Modal from '../../components/modal/Modal';
import { ActiveSessionRow } from '../../constants/ActiveSession';
import Session from '../../session/session';
import { actions } from '../../state/active-sessions/activeSessions-slice';
import { AppDispatch, RootState } from '../../state/store';
import { getDisplayDateTime } from '../../utils/date-utils';
import { getDisplayUsername, getQuantityAndUnit, getValueOrNA } from '../../utils/activeSession-utils';

const PREFIX = 'ActiveSessionReleaseConfirmationModal';
const classes = {
    sessionDetails: `${PREFIX}__session-details`,
};

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '12px',
    [`.${classes.sessionDetails}`]: {
        display: 'grid',
        gridTemplateColumns: '170px 1fr',
        padding: '0px 8px',
        div: {
            height: '24px',
            '&:nth-of-type(2n-1)': {
                textAlign: 'right',
            },
            '&:nth-of-type(2n)': {
                fontWeight: 600,
                paddingLeft: '16px',
            },
        },
    },
});

type OwnProps = {
    onClose: () => void;
    onRelease: () => void;
    row: ActiveSessionRow;
};

const ActiveSessionReleaseConfirmationModal: FC<OwnProps> = ({ onClose, onRelease, row }) => {
    const { t, i18n } = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const releasing = useSelector((state: RootState) => state.activeSessions.releasing);

    const formattedUsername = useMemo(() => getDisplayUsername(row), [row]);

    const releaseLicense = useCallback(async () => {
        const resultAction = await dispatch(
            actions.releaseLicense({
                formattedUsername,
                sessionToken: row.sessionToken,
            })
        );

        if (actions.releaseLicense.rejected.match(resultAction)) {
            onClose();
        } else {
            onRelease();
        }
    }, [dispatch, formattedUsername, row.sessionToken, onClose, onRelease]);

    const formattedQuantity = useMemo(() => getQuantityAndUnit(row, i18n), [row, i18n]);

    const formattedIpAddress = useMemo(() => getValueOrNA(row?.privateIpAddress, i18n), [row?.privateIpAddress, i18n]);

    const formattedMachineId = useMemo(() => getValueOrNA(row?.machineId, i18n), [row?.machineId, i18n]);

    return (
        <Modal
            closeButtonLabel={t('active-session-release-confirmation-modal.button.cancel')}
            onClose={onClose}
            submitButtonLabel={t('active-session-release-confirmation-modal.button.release')}
            onSubmit={releaseLicense}
            disableSubmitButton={releasing}
            title={t('active-session-release-confirmation-modal.title.release-license')}
        >
            <Container>
                <div>{t('active-session-release-confirmation-modal.confirmationMessage.question')}</div>
                <div className={classes.sessionDetails}>
                    <div>
                        {t('active-session-release-confirmation-modal.confirmationMessage.detail-fields.username')}
                    </div>
                    <div>{formattedUsername}</div>
                    <div>
                        {t('active-session-release-confirmation-modal.confirmationMessage.detail-fields.product')}
                    </div>
                    <div>{row.licence.productProfile.name}</div>
                    <div>
                        {t('active-session-release-confirmation-modal.confirmationMessage.detail-fields.quantity')}
                    </div>
                    <div>{formattedQuantity}</div>
                    {Session.displayMachineDetailsInActiveSessions() && (
                        <>
                            <div>
                                {t(
                                    'active-session-release-confirmation-modal.confirmationMessage.detail-fields.ipAddress'
                                )}
                            </div>
                            <div>{formattedIpAddress}</div>
                            <div>
                                {t(
                                    'active-session-release-confirmation-modal.confirmationMessage.detail-fields.machineId'
                                )}
                            </div>
                            <div>{formattedMachineId}</div>
                        </>
                    )}
                    <div>
                        {t('active-session-release-confirmation-modal.confirmationMessage.detail-fields.started')}
                    </div>
                    <div>{getDisplayDateTime(row.acquiredAt)}</div>
                </div>
            </Container>
        </Modal>
    );
};

export default ActiveSessionReleaseConfirmationModal;
