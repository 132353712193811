import React, { FC, useCallback } from 'react';
import { Button, dialogClasses, dialogTitleClasses, paperClasses, styled } from '@mui/material';
import { Dialog, DialogActions, DialogClose, DialogContent, DialogTitle } from '@nuix/common-ui';
import colors from '../../theme/colors';

const PREFIX = 'Modal';
const classes = {
    content: `${PREFIX}__content`,
    footer: `${PREFIX}__footer`,
    title: `${PREFIX}__title`,
    titlePanel: `${PREFIX}__title-panel`,
};

const DialogWrapper = styled(Dialog, {
    shouldForwardProp: prop => prop !== 'hasbanner' && prop !== 'width',
})<{ hasbanner: boolean; width: string }>(({ hasbanner, width }) => ({
    [`.${paperClasses.root}.${dialogClasses.paper}`]: {
        color: colors.ui80,
        minWidth: width,
        maxWidth: '800px',
        padding: 0,
    },
    [`.${classes.content}`]: {
        padding: `${hasbanner ? 0 : '24px 32px'}`,
        minHeight: '74px',
    },
    [`.${classes.footer}`]: {
        height: '48px',
    },
    '.close-button': {
        borderRadius: '50%',
        height: '24px',
        width: '24px',
        margin: '12px 8px 12px 0',
        svg: {
            height: '16px',
            width: '16px',
        },
    },
    [`.${dialogTitleClasses.root}.${classes.title}`]: {
        fontSize: '16px',
        lineHeight: '20px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    [`.${classes.titlePanel}`]: {
        borderBottom: `1px solid ${colors.ui30}`,
        display: 'grid',
        gridTemplateColumns: '1fr 32px',
        height: '48px',
    },
}));

type OwnProps = {
    children?: React.ReactNode;
    closeButtonLabel: string;
    disableSubmitButton?: boolean;
    hasBanner?: boolean;
    hideButtons?: boolean;
    onClose: () => void;
    onSubmit?: Function;
    submitButtonLabel?: string;
    title: string;
    width?: string;
};

const Modal: FC<OwnProps> = ({
    children,
    closeButtonLabel,
    disableSubmitButton,
    hasBanner = false,
    hideButtons = false,
    onClose,
    onSubmit,
    submitButtonLabel,
    title,
    width = '500px',
}) => {
    const handleClose = useCallback(
        (event, reason) => {
            if (hideButtons && reason === 'backdropClick') {
                return;
            }

            onClose();
        },
        [hideButtons, onClose]
    );

    return (
        <DialogWrapper onClose={handleClose} hasbanner={hasBanner} width={width} open>
            <div className={classes.titlePanel}>
                <DialogTitle className={classes.title}>{title}</DialogTitle>
                {!hideButtons && <DialogClose onClose={onClose} />}
            </div>
            <DialogContent dividers className={classes.content}>
                {children}
            </DialogContent>
            <DialogActions className={classes.footer}>
                {!hideButtons && (
                    <>
                        <Button variant={submitButtonLabel ? 'link' : 'contained'} onClick={onClose}>
                            {closeButtonLabel}
                        </Button>
                        {submitButtonLabel && (
                            <Button
                                variant='contained'
                                onClick={() => onSubmit && onSubmit()}
                                disabled={disableSubmitButton ?? false}
                            >
                                {submitButtonLabel}
                            </Button>
                        )}
                    </>
                )}
            </DialogActions>
        </DialogWrapper>
    );
};

export default Modal;
