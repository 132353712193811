/**
 * Callback function for `i18next-resources-to-backend`.
 */
const customerPortal18nBackend = (language, namespace, callback) => {
    import(`../locales/${language}/${namespace}.json`)
        .then(({ default: translations }) => {
            callback(null, translations);
        })
        .catch(error => {
            callback(error, null);
        });
};

export { customerPortal18nBackend };
