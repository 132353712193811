import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    IconButton,
    InputAdornment,
    inputAdornmentClasses,
    iconButtonClasses,
    inputBaseClasses,
    styled,
} from '@mui/material';
import { TextField as NomiTextField, TextFieldProps } from '@nuix/common-ui';
import { MuiSearchIcon } from '@nuix/nomi-icons';

const TextField = styled(NomiTextField)(({ theme }) => ({
    width: '400px',
    [`.${inputBaseClasses.root}`]: {
        paddingLeft: '4px',
    },
    [`.${inputBaseClasses.input}`]: {
        height: '24px',
        margin: '5px 38px 5px 0',
    },
    [`.${iconButtonClasses.root}`]: {
        padding: '4px',
    },
    [`.${inputAdornmentClasses.root}`]: {
        margin: 0,
    },
}));

interface OwnProps extends TextFieldProps {
    onButtonClick: (value) => void;
}

const SearchInput: FC<OwnProps> = ({ onButtonClick, placeholder, value, ...restProps }) => {
    const { t } = useTranslation();
    const placeholderValue = useMemo(() => placeholder || t('search-input.placeholder.search'), [placeholder, t]);
    return (
        <TextField
            data-testid='search-input'
            {...restProps}
            placeholder={placeholderValue}
            value={value}
            InputProps={{
                startAdornment: (
                    <InputAdornment position='start'>
                        <IconButton aria-label='search' onClick={() => onButtonClick(value)}>
                            <MuiSearchIcon height={16} width={16} />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            fullWidth
        />
    );
};

export default SearchInput;
