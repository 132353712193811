import React, { FC, useCallback, useMemo } from 'react';
import { iconButtonClasses, styled } from '@mui/material';
import { DataGrid as MuiDataGrid, DataGridProps, gridClasses, GridSortDirection, GridSortItem } from '@mui/x-data-grid';
import { applyOpacityToHex } from '@nuix/common-ui';
import { Direction } from '../../constants/Pagination';
import colors from '../../theme/colors';
import DataGridPagination from './DataGridPagination';

export const tableClasses = {
    cellIconWithValue: 'table__cell-icon-with-value',
};

const DataGrid = styled(MuiDataGrid)({
    color: colors.ui80,
    border: 0,
    [`.${gridClasses.overlayWrapperInner}`]: {
        display: 'none',
    },
    [`.${gridClasses.filler} > div`]: {
        border: 0,
    },
    [`.${gridClasses.cell}`]: {
        border: 0,
        fontSize: '14px',
        padding: '0 8px',
        alignItems: 'center',
        justifyContent: 'center',
        ':focus, :focus-within': {
            outline: 'none',
        },
        div: {
            display: 'flex',
            alignItems: 'center',
            '&:has(.MuiIconButton-root)': {
                height: '100%',
                justifyContent: 'center',
            },
        },
        [`.${iconButtonClasses.root}`]: {
            color: applyOpacityToHex(colors.primary60, 0.24),
            '&:hover': {
                color: colors.primary70,
            },
            '&:active': {
                backgroundColor: 'transparent',
                color: colors.primary50,
            },
            '&:focus': {
                color: colors.primary70,
            },
            '&:disabled': {
                color: applyOpacityToHex(colors.ui50, 0.24),
            },
        },
        [`.${tableClasses.cellIconWithValue}`]: {
            marginRight: '8px',
            svg: {
                height: '20px',
                width: '20px',
            },
        },
    },
    [`.${gridClasses.columnHeader}`]: {
        borderBottom: `2px solid ${colors.secondary50}!important`,
        padding: '0 8px',
    },
    [`.${gridClasses.columnHeader}:focus, .${gridClasses.columnHeader}:focus-within`]: {
        outline: 'none',
    },
    [`.${gridClasses.columnHeaderTitle}`]: {
        fontWeight: 600,
    },
    [`.${gridClasses.columnHeaderTitleContainer}`]: {
        justifyContent: 'space-between',
    },
    [`.${gridClasses['container--top']}::after`]: {
        backgroundColor: colors.secondary50,
        height: '2px',
    },
    [`.${gridClasses.row}`]: {
        borderBottom: `1px solid ${colors.ui30}`,
        '&.Mui-selected': {
            backgroundColor: 'transparent',
        },
        '&:active': {
            backgroundColor: applyOpacityToHex(colors.primary60, 0.16),
            borderBottom: `1px solid ${colors.primary60}`,
        },
        '&:hover': {
            backgroundColor: applyOpacityToHex(colors.primary60, 0.08),
            borderBottom: `1px solid ${colors.primary60}`,
        },
    },
    [`.${gridClasses.iconButtonContainer}`]: {
        [`.${iconButtonClasses.root}`]: {
            borderRadius: '50%',
            color: colors.secondary50,
        },
    },
    [`.${gridClasses.selectedRowCount}`]: {
        margin: 0,
    },
    [`.${gridClasses.footerContainer}`]: {
        borderTopColor: colors.ui50,
    },
});

type PageSortType = {
    direction: string;
    fieldName: string;
};

type ResultType = {
    content: any[];
    number?: number;
    numberOfElements: number;
    size?: number;
    totalElements: number;
    totalPages: number;
};

interface OwnProps extends DataGridProps {
    onPageNumberChanged: (value: number) => void;
    onPageSizeChanged: (value: number) => void;
    onPageSortChange: (value: PageSortType) => void;
    pageNumber: number;
    pageSize: number;
    pageSort: PageSortType;
    result?: ResultType;
}

const Table: FC<OwnProps> = ({
    onPageNumberChanged,
    onPageSizeChanged,
    onPageSortChange,
    pageNumber,
    pageSize,
    pageSort,
    result,
    ...rootProps
}) => {
    const sortModel = useMemo(
        () => [
            {
                field: pageSort.fieldName,
                sort: pageSort.direction?.toLowerCase() || ('desc' as GridSortDirection),
            } as GridSortItem,
        ],
        [pageSort]
    );

    const paginationModel = useMemo(
        () => ({
            pageSize,
            page: pageNumber,
        }),
        [pageSize, pageNumber]
    );

    const onSortModelChange = useCallback(
        model => {
            if (model.length === 0) {
                onPageSortChange({
                    fieldName: pageSort.fieldName,
                    direction: pageSort.direction === Direction.DESC ? Direction.ASC : Direction.DESC,
                });
            } else {
                onPageSortChange({
                    fieldName: model[0].field,
                    direction: model[0].sort.toUpperCase(),
                });
            }
        },
        [onPageSortChange, pageSort]
    );

    const rowCount = useMemo(() => result?.numberOfElements || 0, [result]);

    return (
        <DataGrid
            autoPageSize
            columnHeaderHeight={34}
            data-testid={'table'}
            disableColumnMenu
            onSortModelChange={onSortModelChange}
            paginationMode={'server'}
            paginationModel={paginationModel}
            rowCount={rowCount}
            rowHeight={33}
            rows={result?.content || []}
            sortModel={sortModel}
            {...rootProps}
            slots={{
                pagination: DataGridPagination,
            }}
            slotProps={{
                pagination: {
                    hideSelectedMessage: !rootProps?.checkboxSelection,
                    numberOfElements: rowCount,
                    onPageNumberChanged,
                    onPageSizeChanged,
                    pageNumber,
                    pageSize,
                    totalElements: result?.totalElements || 0,
                    totalPages: result?.totalPages || 0,
                },
            }}
        />
    );
};

export default Table;
