import colors from './colors';

const MuiToolbar = {
    root: {
        color: colors.primary50,
        borderBottom: `1px solid ${colors.ui30}`,
    },
    regular: {
        minHeight: 0,
        '@media (min-width: 600px)': {
            minHeight: 0,
        },
    },
    gutters: {
        '@media (min-width: 600px)': {
            paddingLeft: '24px',
            paddingRight: '24px',
        },
    },
};

export default MuiToolbar;
