import Config from '../configs/config';
import Session from '../session/session';

const { apis } = Config;

const Auth = {
    handleLogin,
    handleLogout,
    isAuthenticated,
};

/**
 * Forwards the user to the UAA login page and redirect to protected resource after successful authentication.
 */
function handleLogin() {
    const origin = window.location.origin;
    window.location.replace(
        `${apis.uaa}/oauth/authorize?client_id=${apis.clientId}&response_type=token&redirect_uri=${origin}/callback`
    );
}

/**
 * Resets items in session storage associated with the authenticated session.
 */
function handleLogout() {
    Session.destroy();
    Session.setLocation(window.location.origin);
    window.location.replace(`${apis.uaa}/logout.do?redirect=${window.location.origin}&client_id=${apis.clientId}`);
}

/**
 * If user session is authenticated.
 *
 * @returns {boolean} true if user is authenticated, false otherwise.
 */
function isAuthenticated() {
    return Session.getToken() != null;
}

export default Auth;
