import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ClickAwayListener, dialogActionsClasses, Popper as MuiPopper, styled } from '@mui/material';
import TrapFocus from '@mui/material/Unstable_TrapFocus';
import { DateRange } from '@mui/x-date-pickers-pro';
import { DialogActions } from '@nuix/common-ui';
import shadows from '@nuix/common-ui/dist/styled-components/themes/shadows';
import dayjs, { Dayjs } from 'dayjs';
import colors from '../../theme/colors';
import Calendar from './Calendar';

const PREFIX = 'DateRangePickerPopper';
const classes = {
    root: `${PREFIX}__root`,
    calenderWrapper: `${PREFIX}__calender-wrapper`,
};

const Popper = styled(MuiPopper)({
    boxShadow: shadows[2],
    borderRadius: '4px',
    [`.${classes.root}`]: {
        backgroundColor: colors.ui00,
        borderRadius: '4px',
    },
    [`.${classes.calenderWrapper}`]: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        '> div:first-of-type': {
            borderRight: `1px solid ${colors.ui30}`,
        },
    },
    [`.${dialogActionsClasses.root}`]: {
        borderTop: `1px solid ${colors.ui30}`,
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
        'button:last-child': {
            marginLeft: '20px',
        },
    },
});

type OwnProps = {
    anchorEl: HTMLElement | null;
    onAccept: () => void;
    onCancel: () => void;
    onChange: (value: DateRange<Dayjs>) => void;
    open: boolean;
    value: DateRange<Dayjs>;
};

const DateRangePickerPopper: FC<OwnProps> = ({ anchorEl, onAccept, onCancel, onChange, open, value }) => {
    const { t } = useTranslation();

    const [lastReviewingDate, setLastReviewingDate] = useState<Dayjs | null>(null);

    const startCalendarDefaultMonth = useMemo(
        () => (!value[0] && !value[1] ? dayjs().subtract(1, 'month').startOf('month') : null),
        [value]
    );

    const disabledAccept = useMemo(
        () =>
            !value[0] ||
            !value[1] ||
            !dayjs(value[0]).isValid() ||
            !dayjs(value[1]).isValid() ||
            dayjs(value[0]) > dayjs(value[1]),
        [value]
    );

    const handleSetTodayInStartCalendar = useCallback(() => {
        const today = dayjs().startOf('day');
        onChange([today, today]);
    }, [onChange]);

    const handleSetTodayInEndCalendar = useCallback(() => {
        const today = dayjs().startOf('day');
        onChange([value[0] ? value[0] : today, today]);
    }, [value, onChange]);

    const startCalendarRange = useMemo(() => (!value[0] || value[1] ? 'start' : 'end'), [value]);
    const endCalendarRange = useMemo(() => (value[0] ? 'end' : 'start'), [value]);

    return (
        <Popper open={open} anchorEl={anchorEl} placement='bottom-start' onMouseDown={e => e.preventDefault()}>
            <ClickAwayListener onClickAway={onCancel}>
                <div>
                    <TrapFocus open={open}>
                        <div className={classes.root} tabIndex={-1}>
                            <div className={classes.calenderWrapper}>
                                <Calendar
                                    defaultMonth={startCalendarDefaultMonth}
                                    lastReviewingDate={lastReviewingDate}
                                    onChange={onChange}
                                    onSetToday={handleSetTodayInStartCalendar}
                                    rangePosition={startCalendarRange}
                                    setLastReviewingDate={setLastReviewingDate}
                                    value={value}
                                />
                                <Calendar
                                    lastReviewingDate={lastReviewingDate}
                                    onChange={onChange}
                                    onSetToday={handleSetTodayInEndCalendar}
                                    rangePosition={endCalendarRange}
                                    setLastReviewingDate={setLastReviewingDate}
                                    value={value}
                                />
                            </div>
                            <DialogActions>
                                <Button variant='link' onClick={onCancel}>
                                    {t('date-range-picker-popper.button.cancel')}
                                </Button>
                                <Button variant='contained' onClick={onAccept} disabled={disabledAccept}>
                                    {t('date-range-picker-popper.button.ok')}
                                </Button>
                            </DialogActions>
                        </div>
                    </TrapFocus>
                </div>
            </ClickAwayListener>
        </Popper>
    );
};

export default DateRangePickerPopper;
