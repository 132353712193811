import * as React from 'react';

const Portal = (props) => (
    <svg width={48} height={48} viewBox="0 0 48 48" {...props}>
        <title>{'01_Ico / Product Logos / Portal--Dark / 48px'}</title>
        <defs>
            <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id="linearGradient-1">
                <stop stopColor="#9ED7EB" offset="0%"></stop>
                <stop stopColor="#0A7BA5" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="42.5584816%" y1="19.2173499%" x2="87.6786183%" y2="100%" id="linearGradient-2">
                <stop stopColor="#0C9ACE" offset="0%"></stop>
                <stop stopColor="#0C9ACE" stopOpacity="0" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="122.663843%" y1="0%" x2="-22.0423413%" y2="100%" id="linearGradient-3">
                <stop stopColor="#FFFFFF" offset="0%"></stop>
                <stop stopColor="#FFFFFF" stopOpacity="0.32" offset="100%"></stop>
            </linearGradient>
        </defs>
        <g id="01_Ico-/-Product-Logos-/-Portal--Dark-/-48px" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Nuix-Portal-Mark" transform="translate(6.000000, 6.000000)">
                <path d="M18,34.2 C26.9470129,34.2 34.2,26.9470129 34.2,18 C34.2,9.05298705 26.9470129,1.8 18,1.8 C9.05298705,1.8 1.8,9.05298705 1.8,18 C1.8,26.9470129 9.05298705,34.2 18,34.2 Z M18,36 C8.0588745,36 0,27.9411255 0,18 C0,8.0588745 8.0588745,0 18,0 C27.9411255,0 36,8.0588745 36,18 C36,27.9411255 27.9411255,36 18,36 Z" id="Mark-Base" fill="url(#linearGradient-1)" fillRule="nonzero"></path>
                <path d="M18,5.4 L18,30.6 C11.0412122,30.6 5.4,24.9587878 5.4,18 C5.4,11.1108 10.928952,5.512956 17.791636,5.40168804 L18,5.4 Z" id="Path" fill="url(#linearGradient-2)"></path>
                <path d="M18,8.775 C23.0948268,8.775 27.225,12.9051732 27.225,18 C27.225,23.0948268 23.0948268,27.225 18,27.225 C12.9051732,27.225 8.775,23.0948268 8.775,18 C8.775,12.9051732 12.9051732,8.775 18,8.775 Z M18,10.575 C13.8992857,10.575 10.575,13.8992857 10.575,18 C10.575,22.1007143 13.8992857,25.425 18,25.425 C22.1007143,25.425 25.425,22.1007143 25.425,18 C25.425,13.8992857 22.1007143,10.575 18,10.575 Z" id="Oval" fill="url(#linearGradient-3)" fillRule="nonzero"></path>
            </g>
        </g>
    </svg>
)

export default Portal;