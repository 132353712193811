import colors from './colors';

const MuiTab = {
    root: {
        color: colors.ui80,
        fontSize: '12px',
        fontWeight: 600,
        textTransform: 'capitalize',
        padding: '4px 8px 2px 8px',
        height: '30px',
        minHeight: '30px',
    },
};

export default MuiTab;
