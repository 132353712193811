const Auth_Http = {
    isUnauthenticated,
    isForbidden,
};

/**
 * Whether the request is not authenticated.
 *
 * @param response the response.
 * @returns {boolean}
 */
export function isUnauthenticated(response) {
    return response.status === 401;
}

/**
 * Whether the request is forbidden.
 *
 * @param response the response.
 * @returns {boolean}
 */
export function isForbidden(response) {
    return response.status === 403;
}

export default Auth_Http;
