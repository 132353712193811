import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import {
    AuditReportStatus,
    AuditReportVerificationRow,
    StatusDetailCodeMessage,
} from '../../constants/AuditReportVerification';
import Banner from '../../components/banner/Banner';
import Modal from '../../components/modal/Modal';
import AuditReportVerificationDetailCounts from './AuditReportVerificationDetailCounts';

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 32px',
});

type OwnProps = {
    onClose: () => void;
    onDownload: () => void;
    row: AuditReportVerificationRow;
};

// Only record with PROCESSED, PROCESSED_PARTIALLY, UPLOAD_FAILED or PROCESS_FAILED status will be shown
const AuditReportVerificationDetailModal: FC<OwnProps> = ({ onClose, onDownload, row }) => {
    const { i18n, t } = useTranslation();

    const { fileName, status, statusDetails, statusDetailsCode } = row;

    const auditReportStatus = useMemo(() => AuditReportStatus[status], [status]);

    const failed = useMemo(
        () => status === AuditReportStatus.UPLOAD_FAILED.value || status === AuditReportStatus.PROCESS_FAILED.value,
        [status]
    );

    const downloadButtonLabel = useMemo(() => {
        if (status === AuditReportStatus.PROCESSED.value || status === AuditReportStatus.PROCESSED_PARTIALLY.value)
            return t('audit-report-verification-detail-modal.button.download-processed-report');
    }, [status, t]);

    const closeButtonLabel = useMemo(
        () =>
            failed
                ? t('audit-report-verification-detail-modal.button.close')
                : t('audit-report-verification-detail-modal.button.cancel'),
        [failed, t]
    );
    const title = useMemo(
        () => t('audit-report-verification-detail-modal.title.report-file', { fileName }),
        [fileName, t]
    );

    return (
        <Modal
            closeButtonLabel={closeButtonLabel}
            onClose={onClose}
            submitButtonLabel={downloadButtonLabel}
            onSubmit={onDownload}
            title={title}
            hasBanner={true}
            width='380px'
        >
            <Banner severity={auditReportStatus.severity} tooltip={auditReportStatus.tooltip(i18n)}>
                {auditReportStatus.displayName(i18n)}
            </Banner>
            <Container>
                {failed ? (
                    <>
                        {statusDetailsCode && (
                            <div>{StatusDetailCodeMessage[statusDetailsCode]?.(i18n) || statusDetailsCode}</div>
                        )}
                        {statusDetails && statusDetails !== statusDetailsCode && <div>{statusDetails}</div>}
                    </>
                ) : (
                    <AuditReportVerificationDetailCounts row={row} />
                )}
            </Container>
        </Modal>
    );
};

export default AuditReportVerificationDetailModal;
