import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material';
import { RootState } from '../../state/store';
import GlobalMessage from './globalMessage';

const GlobalMessagesContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
    maxWidth: '50%',
    position: 'fixed',
    top: '57px',
    right: '4px',
    zIndex: 9999,
});

const GlobalMessages: FC = () => {
    const messages = useSelector((state: RootState) => state.globalMessages.messages);

    return (
        <GlobalMessagesContainer data-testid='global-messages'>
            {messages.map(message => (
                <GlobalMessage message={message} key={message.id} />
            ))}
        </GlobalMessagesContainer>
    );
};

export default GlobalMessages;
