import React, { FC } from 'react';
import { styled } from '@mui/material';
import InProgressCircle from '../../components/in-progress-circle/InProgressCircle';

const Container = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
});

const LoadingOverlay: FC = () => (
    <Container data-testid='loading-overlay'>
        <InProgressCircle size={50} />
    </Container>
);

export default LoadingOverlay;
