import gql from 'graphql-tag';

export const GET_ORGANISATION_SUMMARY = gql`
    query GetOrganisationSummary {
        getOrganisationSummary {
            id
            name
            numberOfOfflineLicences
            displayMachineDetailsInActiveSessions
        }
    }
`;
