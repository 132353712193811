/* eslint-disable no-unused-vars */
import { Direction, PageSortType } from './Pagination';

export enum AuditType {
    COMPOUND_CAP = 'COMPOUND_CAP',
    ADD_ON_CAP = 'ADD_ON_CAP',
    PAYG = 'PAYG',
}

export enum LicenceMode {
    ONLINE = 'ONLINE',
    OFFLINE = 'OFFLINE',
}
/* eslint-enable no-unused-vars */

export const CapSchedule = i18n => ({
    DAILY: {
        label: i18n.t('consumption.cap-schedule.daily-field.label.day'),
        value: 'DAILY',
    },
    WEEKLY: {
        label: i18n.t('consumption.cap-schedule.weekly-field.label.week'),
        value: 'WEEKLY',
    },
    MONTHLY: {
        label: i18n.t('consumption.cap-schedule.monthly-field.label.month'),
        value: 'MONTHLY',
    },
});

export const DEFAULT_PAGE_SORT: PageSortType = {
    fieldName: 'generatedAt',
    direction: Direction.DESC,
};

type UsageTotalsType = {
    excludedCount: number;
    excludedSize: number;
    includedCount: number;
    includedSize: number;
    totalCount: number;
    totalSize: number;
};

export type ConsumptionRow = {
    auditType: AuditType;
    batchLoadId: string;
    cap: number;
    capSchedule: string;
    caseGuid: string;
    caseName: string;
    createdAt: string;
    dongleName: string;
    generatedAt: string;
    id: string;
    licenceDisplayId: string;
    licenceMode: LicenceMode;
    organisationId: number;
    productProfileId: number;
    productVersion: string;
    sessionToken: string;
    topLevelBinarySize: UsageTotalsType;
    totals: UsageTotalsType;
    totalSinceCapStart: number;
    totalsIncludedSize: number;
    uploadedBy: string;
};

type LastUsageType = {
    caseName: string;
    generatedAt: string;
    productVersion?: string;
    totalProcessed: number;
    username?: string;
};

export type ConsumptionUsageType = {
    auditType?: AuditType;
    convertedOfflineLicenceId: number;
    expiry?: string;
    lastUsage?: LastUsageType;
    licenceMode: LicenceMode;
    name: string;
    productProfile?: string;
    totalCap?: number;
    totalCases: number;
    totalLeft?: number;
    totalProcessed: number;
};

export type ConsumptionUsageSummaryType = {
    totalProcessed: number;
    totalCases: number;
    consumptionUsages: ConsumptionUsageType[];
};
