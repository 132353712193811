import { alertClasses, AlertClasses } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides.js';
import colors from './colors';

export const alertOverrides: Partial<OverridesStyleRules<keyof AlertClasses>> = {
    root: {
        boxShadow: 'none',
        padding: '0 !important',
        [`&.${alertClasses.standard}`]: {
            [`&.${alertClasses.colorInfo}`]: {
                backgroundColor: colors.primary10,
                border: `1px solid ${colors.primary60}`,
                svg: {
                    color: colors.primary60,
                },
            },
            [`&.${alertClasses.colorSuccess}`]: {
                backgroundColor: colors.success10,
                border: `1px solid ${colors.success80}`,
                svg: {
                    color: colors.success80,
                },
            },
            [`&.${alertClasses.colorWarning}`]: {
                backgroundColor: colors.warn10,
                border: `1px solid ${colors.warn80}`,
                svg: {
                    color: colors.warn80,
                },
            },
            [`&.${alertClasses.colorError}`]: {
                backgroundColor: colors.error10,
                border: `1px solid ${colors.error80}`,
                svg: {
                    color: colors.error80,
                },
            },
        },
    },
    icon: {
        padding: 0,
        marginRight: '8px',
        svg: {
            height: '20px!important',
            width: '20px!important',
        },
    },
    message: {
        color: colors.ui80,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        fontSize: '12px',
        lineHeight: '16px',
        padding: '2px 0!important',
        width: '100%',
    },
};
