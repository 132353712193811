import * as React from 'react';

const Export = (props) => (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
        <path
            d="M21.64 7.03c-.43.43-1.13.43-1.56 0l-.6-.6v.19c0 2.08-.7 3.89-2.09 5.41-1.47 1.6-3.52 2.61-5.69 2.76-1.02.07-2-.04-2.95-.33-.15-.04-.29-.09-.43-.15-.91-.33-1.17-1.5-.49-2.19.37-.38.93-.48 1.43-.29.17.06.34.11.51.16.44.11.89.17 1.35.17 1.58 0 2.93-.54 4.04-1.63 1.11-1.09 1.67-2.4 1.67-3.95v-.15l-.61.61c-.43.43-1.12.43-1.55 0s-.43-1.13 0-1.56L18.16 2l3.48 3.48c.43.43.43 1.12 0 1.55ZM5.65 22.08c-.74 0-1.36-.26-1.88-.77-.51-.51-.77-1.14-.77-1.88V5.77c0-.74.26-1.36.77-1.88.51-.51 1.14-.77 1.88-.77h5.51c.73 0 1.33.59 1.33 1.33s-.59 1.33-1.33 1.33H5.65v13.66h13.66v-5.51c0-.73.59-1.33 1.33-1.33s1.33.59 1.33 1.33v5.51c0 .74-.26 1.36-.77 1.88-.51.52-1.14.77-1.88.77H5.65Z"
            fill="currentColor"
        />
    </svg>
)
;

export default Export;
