import React from 'react';
import Auth from '../../auth/auth';
import LoadingOverlay from '../loading-overlay/LoadingOverlay';
import Session from '../../session/session';

const Login = () => {
    if (!Session.getLocation()) {
        Session.setLocation(`${window.location.origin}/licensing/activeSessions`);
    }

    if (!Auth.isAuthenticated()) {
        Auth.handleLogin();
        return <div data-testid='login' />;
    } else {
        window.location.replace(Session.getLocation());
        return <LoadingOverlay />;
    }
};

export default Login;
