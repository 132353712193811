import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { AuditReportVerificationRow } from '../../constants/AuditReportVerification';

const Container = styled('div')({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    paddingLeft: '60px',
    div: {
        height: '24px',
        '&:nth-of-type(2n-1)': {
            textAlign: 'right',
        },
        '&:nth-of-type(2n)': {
            fontWeight: 600,
            paddingLeft: '16px',
        },
    },
});

type OwnProps = {
    row?: AuditReportVerificationRow | null;
};

const AuditReportVerificationDetailCounts: FC<OwnProps> = ({ row }) => {
    const { failedCount, processedCount, totalCount } = row || {};
    const { t } = useTranslation();
    const numberFormat = Intl.NumberFormat('en-US');

    return (
        <Container data-testid='audit-report-verification-detail-counts'>
            <div>{t('audit-report-verification-detail-counts.total-records')}</div>
            <div>{numberFormat.format(totalCount || 0)}</div>
            <div>{t('audit-report-verification-detail-counts.records-verified')}</div>
            <div>{numberFormat.format(processedCount || 0)}</div>
            <div>{t('audit-report-verification-detail-counts.records-failed')}</div>
            <div>{numberFormat.format(failedCount || 0)}</div>
        </Container>
    );
};

export default AuditReportVerificationDetailCounts;
