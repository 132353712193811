import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import Session from '../../session/session';
import { useQuery } from '@apollo/client';
import { GET_ORGANISATION_SUMMARY } from '../../queries/organisationSummary-query';
import { handleError } from '../../utils/error-utils';
import LoadingOverlay from '../loading-overlay/LoadingOverlay';

export const Callback = () => {
    const { i18n } = useTranslation();
    const originalHref = Session.getLocation();
    const { hash } = useLocation();
    const res = queryString.parse(hash);

    // may need later
    const {
        //token_type: tokenType,
        access_token: accessToken,
        //expires_in: expiresIn,
        //jti,
        //error,
    } = res;
    Session.setToken(accessToken);
    const { data, loading, error } = useQuery(GET_ORGANISATION_SUMMARY);
    if (loading) {
        return <LoadingOverlay />;
    }
    if (error) {
        return handleError(error, i18n);
    }
    Session.setHasOfflineLicences(data?.getOrganisationSummary?.numberOfOfflineLicences > 0 || false);
    Session.setDisplayMachineDetailsInActiveSessions(
        data?.getOrganisationSummary?.displayMachineDetailsInActiveSessions || false
    );
    window.location.replace(originalHref);
};
