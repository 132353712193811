import React from 'react';
import { Chip as MuiChip, styled } from '@mui/material';
import { GridColDef, GridRenderCellParams, gridClasses } from '@mui/x-data-grid';
import { formatProductVersion, getOriginalDataSize, getProcessedDataSize } from '../utils/consumption-utils';
import { getDisplayDateTime } from '../utils/date-utils';
import { ConsumptionRow } from './Consumption';
import colors from '../theme/colors';

const Chip = styled(MuiChip)({
    backgroundColor: colors.primary50,
    fontWeight: 600,
    marginTop: '4px',
    width: 'fit-content',
});

const renderDongleName = (params: GridRenderCellParams<ConsumptionRow>) => {
    const { row } = params;
    const { dongleName } = row;
    return <Chip label={dongleName} />;
};

export const getConsumptionByDongleColumns = (i18n, numberFormat): GridColDef<ConsumptionRow>[] => [
    {
        field: 'generatedAt',
        headerName: i18n.t(
            'consumption-by-dongle.get-consumption-by-dongle-columns.generated-date-column.header-name.generated-date'
        ),
        flex: 1.5,
        resizable: true,
        type: 'dateTime',
        valueFormatter: value => getDisplayDateTime(value),
    },
    {
        field: 'dongleName',
        headerName: i18n.t(
            'consumption-by-dongle.get-consumption-by-dongle-columns.dongle-name-column.header-name.dongle-name'
        ),
        flex: 1.5,
        resizable: true,
        renderCell: params => renderDongleName(params),
    },
    {
        field: 'caseName',
        headerName: i18n.t(
            'consumption-by-dongle.get-consumption-by-dongle-columns.case-name-column.header-name.case-name'
        ),
        flex: 2,
        resizable: true,
    },
    {
        field: 'batchLoadId',
        headerName: i18n.t(
            'consumption-by-dongle.get-consumption-by-dongle-columns.batch-load-id-column.header-name.batch-load-id'
        ),
        flex: 2,
        resizable: true,
    },
    {
        field: 'productVersion',
        headerName: i18n.t(
            'consumption-by-dongle.get-consumption-by-dongle-columns.product-version-column.header-name.product-version'
        ),
        flex: 0.8,
        resizable: true,
        valueFormatter: value => formatProductVersion(value),
    },
    {
        field: 'topLevelBinarySizeIncludedSize',
        headerName: i18n.t(
            'consumption-by-dongle.get-consumption-by-dongle-columns.original-data-size-column.header-name.original-data-size'
        ),
        flex: 1,
        sortable: false,
        resizable: true,
        type: 'number',
        renderCell: (params: GridRenderCellParams<ConsumptionRow>) => {
            const { row } = params;
            const originalDataSize = getOriginalDataSize(row, i18n, numberFormat);
            const tooltipTitle =
                originalDataSize === i18n.t('consumption-utils.n-a')
                    ? i18n.t('consumption-utils.not-available')
                    : originalDataSize;
            return (
                <div
                    className={gridClasses['cell--textRight']}
                    data-testid='orgDataSizeTooltip'
                    role='gridcell'
                    title={tooltipTitle}
                >
                    {originalDataSize}
                </div>
            );
        },
    },
    {
        field: 'totalsIncludedSize',
        headerName: i18n.t(
            'consumption-by-dongle.get-consumption-by-dongle-columns.processed-data-size-column.header-name.processed-data-size'
        ),
        flex: 1,
        sortable: false,
        resizable: true,
        type: 'number',
        valueGetter: (value, row) => getProcessedDataSize(row, numberFormat),
    },
];
