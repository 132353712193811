import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Modules from '../../module/modules';
import { getDefaultTab } from '../../module/module-utils';

type ModuleSelectionState = Readonly<{
    selectedModuleId: string;
    selectedModuleTabIds: Record<string, string | undefined>;
}>;

type SelectedModule = {
    moduleId: string;
};

type SelectedModuleTab = {
    moduleId: string;
    tabId: string;
};

const moduleState = (): Record<string, string | undefined> =>
    Object.keys(Modules).reduce(
        (acc, moduleKey) => ({ ...acc, [Modules[moduleKey].id]: getDefaultTab(moduleKey)?.id }),
        {}
    );

const initialState: ModuleSelectionState = {
    selectedModuleId: Modules.LICENSING.id,
    selectedModuleTabIds: moduleState(),
};

const moduleSelectionSlice = createSlice({
    name: 'moduleSelection',
    initialState,
    reducers: {
        selectModule: (state, action: PayloadAction<SelectedModule>) => {
            const { moduleId } = action.payload;
            state.selectedModuleId = moduleId;
        },
        selectTab: (state, action: PayloadAction<SelectedModuleTab>) => {
            const { moduleId, tabId } = action.payload;
            state.selectedModuleTabIds[moduleId] = tabId;
        },
    },
});

const { actions, reducer } = moduleSelectionSlice;
export { actions, initialState, reducer };
