import React, { FC, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, InputLabel, Input, styled } from '@mui/material';
import { TextField } from '@nuix/common-ui';
import colors from '../../theme/colors';

const classes = {
    inputWrapper: 'file-uploader_input-wrapper',
    displayInput: 'file-uploader_display-input',
    hiddenInput: 'file-uploader_hidden-input',
    button: 'file-uploader_button',
};

const FileUploaderContainer = styled(InputLabel)(({ theme }) => ({
    color: theme.palette['ui-80'].main,
    fontSize: '12px',
    [`.${classes.inputWrapper}`]: {
        display: 'flex',
        columnGap: '2px',
    },
    [`.${classes.displayInput}`]: {
        width: 'calc(100% - 80px)',
        whiteSpace: 'normal',
    },
    [`.${classes.hiddenInput}`]: {
        display: 'none',
    },
    [`.${classes.button}`]: {
        backgroundColor: colors.primary60,
    },
}));

export type FileType = {
    name: string;
};

type OwnProps = {
    accept: string;
    disabled?: boolean;
    errors?: Array<string>;
    files: Array<FileType>;
    label: string;
    onChange: Function;
    placeholder: string;
};

const FileUploader: FC<OwnProps> = ({ accept, disabled = false, errors = [], files, label, onChange, placeholder }) => {
    const { t } = useTranslation();

    const inputRef = useRef<HTMLDivElement>();

    const disableUpload = useMemo(() => disabled ?? false, [disabled]);

    const fileNamesToDisplay = useMemo(
        () => (Array.isArray(files) ? files.map(file => file.name).join(', ') : ''),
        [files]
    );

    const handleOnChange = useCallback(
        e => {
            const value = [...e.target.files];

            if (value.length > 0) {
                onChange(value);
                e.target.value = null;
            }
        },
        [onChange]
    );

    const handleBrowse = useCallback(() => {
        inputRef.current?.click();
    }, []);

    return (
        <FileUploaderContainer htmlFor='file-upload-input' data-testid='file-uploader'>
            {label}
            <div className={classes.inputWrapper}>
                <Input
                    id='file-upload-input'
                    type='file'
                    inputProps={{
                        accept,
                    }}
                    onChange={handleOnChange}
                    ref={inputRef}
                    className={classes.hiddenInput}
                    disabled={disableUpload}
                />
                <div className={classes.displayInput}>
                    <TextField
                        onClick={handleBrowse}
                        placeholder={placeholder}
                        fullWidth
                        InputProps={{
                            readOnly: true,
                        }}
                        value={fileNamesToDisplay}
                        error={errors?.length > 0}
                        helperText={errors?.[0]}
                        disabled={disableUpload}
                    />
                </div>
                <Button className={classes.button} variant='contained' disabled={disableUpload} onClick={handleBrowse}>
                    {t('file-uploader.button.browse')}
                </Button>
            </div>
        </FileUploaderContainer>
    );
};

export default FileUploader;
export { classes as fileUploaderClasses };
