import colors from './colors';

const MuiTabs = {
    root: {
        minHeight: '32px',
    },
    indicator: {
        backgroundColor: colors.primary50,
    },
};

export default MuiTabs;
