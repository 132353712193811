import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_PAGE_SORT } from '../../constants/AuditReportVerification';
import { DEFAULT_PAGE_SIZE, PageSortType } from '../../constants/Pagination';

type AuditReportVerificationState = Readonly<{
    pageSize: number;
    pageSort: PageSortType;
}>;

const initialState: AuditReportVerificationState = {
    pageSize: DEFAULT_PAGE_SIZE,
    pageSort: DEFAULT_PAGE_SORT,
};

const auditReportVerificationSlice = createSlice({
    name: 'auditReportVerification',
    initialState,
    reducers: {
        resetPageSort(state) {
            state.pageSort = DEFAULT_PAGE_SORT;
        },
        updatePageSize(state, { payload }) {
            state.pageSize = payload;
        },
        updatePageSort(state, { payload }) {
            state.pageSort = payload;
        },
    },
});

const { actions, reducer } = auditReportVerificationSlice;

export { actions, initialState, reducer };
