import React, { FC, useCallback, useMemo } from 'react';
import {
    DateRange,
    DateRangePickerDay as MuiDateRangePickerDay,
    dateRangePickerDayClasses,
    DateRangePickerDayProps,
    RangePosition,
} from '@mui/x-date-pickers-pro';
import { styled } from '@mui/material';
import { applyOpacityToHex } from '@nuix/common-ui';
import { Dayjs } from 'dayjs';
import colors from '../../theme/colors';

type DateRangePickerDayType = {
    endBorder: boolean;
    endPreview: boolean;
    previewing: boolean;
    startBorder: boolean;
    startPreview: boolean;
};

const DateRangePickerDay = styled(MuiDateRangePickerDay, {
    shouldForwardProp: prop =>
        prop !== 'endBorder' &&
        prop !== 'endPreview' &&
        prop !== 'previewing' &&
        prop !== 'startBorder' &&
        prop !== 'startPreview',
})<DateRangePickerDayType>(({ endBorder, endPreview, previewing, startBorder, startPreview }) => ({
    paddingLeft: `${startPreview && !startBorder ? '4px' : '0'}`,
    paddingRight: `${endPreview && !endBorder ? '4px' : '0'}`,
    marginLeft: `${startBorder ? '4px' : '0'}`,
    marginRight: `${endBorder ? '4px' : '0'}`,
    [`.Mui-selected.${dateRangePickerDayClasses.day}`]: {
        backgroundColor: colors.primary60,
        color: colors.ui00,
        fontWeight: 400,
        '&:hover': {
            backgroundColor: colors.primary70,
        },
    },
    [`.${dateRangePickerDayClasses.day}`]: {
        color: colors.ui80,
        borderColor: colors.primary60,
        fontSize: '12px',
        marginLeft: `${startBorder || startPreview ? '0' : '4px'}`,
        marginRight: `${endBorder || endPreview ? '0' : '4px'}`,
        height: '21px',
        width: '21px',
        '&:hover': {
            backgroundColor: applyOpacityToHex(colors.primary60, 0.08),
            border: '0 !important',
        },
        '&:focus': {
            border: `3px solid ${applyOpacityToHex(colors.primary60, 0.24)}`,
        },
        '&.Mui-disabled:not(.Mui-selected)': {
            color: colors.ui50,
        },
    },
    [`.${dateRangePickerDayClasses.rangeIntervalPreview}`]: {
        alignContent: 'center',
        border: '1px dotted transparent',
        height: '24px',
        '&:has(button)': {
            borderTopColor: `${previewing ? colors.ui40 : 'transparent'}`,
            borderBottomColor: `${previewing ? colors.ui40 : 'transparent'}`,
            borderLeftColor: `${startPreview || (previewing && startBorder) ? colors.ui40 : 'transparent'}`,
            borderRightColor: `${endPreview || (previewing && endBorder) ? colors.ui40 : 'transparent'}`,
            borderTopLeftRadius: `${startPreview || (previewing && startBorder) ? '50%' : 'unset'}`,
            borderBottomLeftRadius: `${startPreview || (previewing && startBorder) ? '50%' : 'unset'}`,
            borderTopRightRadius: `${endPreview || (previewing && endBorder) ? '50%' : 'unset'}`,
            borderBottomRightRadius: `${endPreview || (previewing && endBorder) ? '50%' : 'unset'}`,
        },
    },
    [`&.${dateRangePickerDayClasses.rangeIntervalDayHighlight}:has(button)`]: {
        backgroundColor: applyOpacityToHex(colors.primary60, 0.04),
    },
    [`&.${dateRangePickerDayClasses.rangeIntervalDayHighlightStart}:has(.Mui-selected.${dateRangePickerDayClasses.day})`]:
        {
            background: `linear-gradient(to right, ${colors.ui00} 50%, ${applyOpacityToHex(colors.primary60, 0.04)} 50%)`,
        },
    [`&.${dateRangePickerDayClasses.rangeIntervalDayHighlightEnd}:has(.Mui-selected.${dateRangePickerDayClasses.day})`]:
        {
            background: `linear-gradient(to left, ${colors.ui00} 50%, ${applyOpacityToHex(colors.primary60, 0.04)} 50%)`,
        },
    [`&.${dateRangePickerDayClasses.rangeIntervalDayHighlightStart}.${dateRangePickerDayClasses.rangeIntervalDayHighlightEnd}:has(.Mui-selected.${dateRangePickerDayClasses.day})`]:
        {
            background: colors.ui00,
        },
}));

interface OwnProps extends DateRangePickerDayProps<Dayjs> {
    calenderValue: DateRange<Dayjs>;
    lastReviewingDate: Dayjs | null;
    rangePosition: RangePosition;
    setLastReviewingDate: (value: Dayjs | null) => void;
}

const CalendarDay: FC<OwnProps> = ({
    calenderValue,
    lastReviewingDate,
    rangePosition,
    setLastReviewingDate,
    ...restProps
}) => {
    const { day, isPreviewing } = restProps;

    const previewing = useMemo(
        () =>
            !!(
                isPreviewing ||
                (lastReviewingDate &&
                    calenderValue?.[0] &&
                    day >= calenderValue[0] &&
                    lastReviewingDate > calenderValue[0] &&
                    (!calenderValue?.[1] || day >= calenderValue[1]) &&
                    lastReviewingDate >= day)
            ),
        [calenderValue, day, isPreviewing, lastReviewingDate]
    );
    const endBorder = useMemo(() => day.day() === 6 || day.date() === day.daysInMonth(), [day]);

    const endPreview = useMemo(
        () => (previewing && lastReviewingDate && day.isSame(lastReviewingDate)) || false,
        [day, previewing, lastReviewingDate]
    );

    const startBorder = useMemo(() => day.day() === 0 || day.date() === 1, [day]);

    const startPreview = useMemo(
        () =>
            (previewing &&
                ((calenderValue?.[0] && day.isSame(calenderValue[0]) && !calenderValue?.[1]) ||
                    (calenderValue?.[1] && day.isSame(calenderValue[1])))) ||
            false,
        [calenderValue, day, previewing]
    );

    const handleDayHover = useCallback(() => {
        if (calenderValue?.[0] && rangePosition === 'end') {
            setLastReviewingDate(day);
        }
    }, [calenderValue, day, rangePosition, setLastReviewingDate]);

    const handleDayLeave = useCallback(() => {
        setLastReviewingDate(null);
    }, [setLastReviewingDate]);

    return (
        <DateRangePickerDay
            {...restProps}
            endBorder={endBorder}
            endPreview={endPreview}
            onMouseEnter={handleDayHover}
            onMouseLeave={handleDayLeave}
            previewing={previewing}
            startBorder={startBorder}
            startPreview={startPreview}
        />
    );
};

export default CalendarDay;
