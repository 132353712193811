export const DEFAULT_PAGE_SIZE = 50;

export const PAGE_SIZE_OPTIONS = ['10', '20', '50', '100'];

/* eslint-disable no-unused-vars */
export enum Direction {
    ASC = 'ASC',
    DESC = 'DESC',
}
/* eslint-enable no-unused-vars */

export type PageSortType = {
    fieldName: string;
    direction: Direction;
};
