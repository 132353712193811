/**
 * Modules.
 */
import Session from '../session/session';

export type TabType = {
    id: string;
    label: (i18n) => string;
    link: string;
    default: boolean;
};

export type ModuleType = {
    id: string;
    title: (i18n) => string;
    order: number;
    icon: any;
    tabs: Array<TabType>;
};

const Modules: Record<string, ModuleType> = {
    LICENSING: {
        id: 'LICENSING',
        title: i18n => i18n.t('modules.modules.licensing-field.title.licensing'),
        order: 0,
        icon: {
            id: 'Licensing',
            color: '#FFFFFF',
        },
        tabs: [
            {
                id: 'activeSessions',
                label: i18n => i18n.t('modules.modules.licensing-field.tabs.active-sessions-tab.label.active-sessions'),
                link: '/licensing/activeSessions',
                default: true,
            },
        ],
    },
    // This is the least permission required to see all the tabs in this menu.
    ...(Session.hasPermission('cls.p.off.audit_rep.r_own_org') && Session.hasOfflineLicences()
        ? {
              DATA_USAGE: {
                  id: 'DATA_USAGE',
                  title: i18n => i18n.t('modules.modules.data-usage-field.title.data-usage'),
                  order: 1,
                  icon: {
                      id: 'DataUsage',
                      color: '#FFFFFF',
                  },
                  tabs: [
                      ...(Session.hasPermission('cls.p.off.audit_rep.r_own_org')
                          ? [
                                {
                                    id: 'auditReportVerification',
                                    label: i18n =>
                                        i18n.t(
                                            'modules.modules.data-usage-field.tabs.audit-report-verification-tab.label.audit-report-verification'
                                        ),
                                    link: '/dataUsage/auditReportVerification',
                                    default: true,
                                },
                                {
                                    id: 'consumptionByDongle',
                                    label: i18n =>
                                        i18n.t(
                                            'modules.modules.data-usage-field.tabs.consumption-by-dongle-tab.label.consumption-by-dongle'
                                        ),
                                    link: '/dataUsage/consumptionByDongle',
                                    default: false,
                                },
                            ]
                          : []),
                  ],
              },
          }
        : {}),
};

export default Modules;
