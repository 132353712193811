import Modules, { TabType } from './modules';

/**
 * Gets the default tab for given module key.
 *
 * @param key the module key.
 * @returns {{default: boolean, link: string, label: string, key: string}}
 */
export const getDefaultTab = (key: string): TabType | undefined => Modules[key].tabs.find(tab => tab.default);

export const getTabLink = (moduleId: string, tabId: string) =>
    Modules[moduleId]?.tabs.find(tab => tab.id === tabId)?.link || '';

export const getModuleTabIdByLink = (link: string) => {
    for (const [moduleId, module] of Object.entries(Modules)) {
        const tab = module.tabs.find(tab => tab.link === link);
        if (tab) {
            return { moduleId, tabId: tab.id };
        }
    }
    return {};
};
