import Auth from '../auth/auth';
import Auth_Http from '../auth/auth-http';
import Session from '../session/session';

type FetchOptions = RequestInit & { headers?: HeadersInit };

export const getAuthHeader = () => {
    const token = Session.getToken();

    if (!token) {
        return {};
    }

    return {
        Authorization: `Bearer ${token}`,
    };
};

const getUrl = (url: string, queryParameters?: Record<string, any> | null | undefined) => {
    if (!queryParameters) {
        return url;
    }
    const parameters = Object.entries(queryParameters)
        .reduce<string[]>((result, [key, value]) => {
            const paraValue = Array.isArray(value) ? value.join(',') : value;
            result.push(`${key}=${encodeURIComponent(paraValue)}`);
            return result;
        }, [])
        .join('&');
    return `${url}${url.includes('?') ? '&' : '?'}${parameters}`;
};

const getFileNameFromResponse = (response: Response) => {
    const contentDisposition = response.headers.get('Content-Disposition');
    if (contentDisposition && contentDisposition.includes('attachment')) {
        const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (filenameMatch && filenameMatch.length === 2) {
            return filenameMatch[1];
        }
    }
    return 'downloadedFile';
};

const handleHttpError = async response => {
    if (Auth_Http.isUnauthenticated(response)) {
        Session.setLocation(window.location.href);
        Auth.handleLogin();
    } else {
        const error = await response.json();
        throw new Error(error?.message || `HTTP status code: ${response.status}`);
    }
};

export const downloadFile = async (
    url: string,
    queryParameters: Record<string, any> | null = null,
    options: FetchOptions = {},
    otherParams: { isPresignedURLDownload: boolean; downloadFileName: string } | null = null
) => {
    const fullUrl = getUrl(url, queryParameters);
    const authHeader = otherParams?.isPresignedURLDownload ? {} : getAuthHeader();
    const response = await fetch(fullUrl, {
        ...options,
        headers: {
            ...(options.headers || {}),
            ...authHeader,
            'Access-Control-Allow-Origin': '*',
        } as HeadersInit,
    });

    if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = otherParams?.downloadFileName || getFileNameFromResponse(response);
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
    } else {
        await handleHttpError(response);
    }
};

export const httpDelete = async (url: string, options: FetchOptions = {}) => {
    const response = await fetch(url, {
        ...options,
        method: 'DELETE',
        credentials: 'include',
        headers: {
            ...(options.headers || {}),
            ...getAuthHeader(),
        } as HeadersInit,
    });

    if (!response.ok) {
        await handleHttpError(response);
    }
};
