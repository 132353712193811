import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Alert, alertClasses, AlertTitle, alertTitleClasses, iconButtonClasses, Slide, styled } from '@mui/material';
import shadows from '@nuix/common-ui/dist/styled-components/themes/shadows';
import { actions, GlobalMessagesType } from '../../state/global-messages/globalMessages-slice';
import colors from '../../theme/colors';

const GlobalMessageContainer = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette['ui-00'].main,
    borderRadius: '4px',
    boxShadow: shadows[2],
    [`.${alertTitleClasses.root}`]: {
        color: colors.ui80,
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '20px',
        margin: 0,
    },
    [`.${alertClasses.root}`]: {
        flexDirection: 'row',
        columnGap: '8px',
        [`.${alertClasses.icon}`]: {
            backgroundColor: 'transparent!important',
            alignItems: 'start',
            padding: '10px 0 10px 8px',
            marginRight: 0,
        },
        [`.${alertClasses.message}`]: {
            margin: '8px 0',
            '> div': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            },
            strong: {
                fontWeight: 600,
            },
        },
        [`.${alertClasses.action}`]: {
            margin: 0,
            position: 'static',
            padding: '8px 4px',
            [`.${iconButtonClasses.root}`]: {
                borderRadius: '50%',
                padding: '4px',
            },
            svg: {
                color: `${colors.secondary50}!important`,
                height: '16px',
                width: '16px',
            },
        },
    },
}));

type OwnProps = {
    message: GlobalMessagesType;
};

const GlobalMessage: FC<OwnProps> = ({ message }) => {
    const { autoHideDuration, content, severity, title } = message;

    const dispatch = useDispatch();
    const [shown, setShown] = useState(false);
    const [timer, setTimer] = useState<NodeJS.Timeout>();

    const handleClose = useCallback(() => {
        setShown(false);

        setTimeout(() => {
            dispatch(actions.globalMessageRemoved(message));
        }, 1000);
    }, [dispatch, message]);

    useEffect(() => {
        setTimeout(() => {
            setShown(true);
        }, 25);

        clearTimeout(timer);

        const timeout = setTimeout(() => {
            handleClose();
        }, autoHideDuration);
        setTimer(timeout);

        // Cleanup function
        return () => clearTimeout(timeout);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Slide direction='left' in={shown} data-testid='global-message'>
            <GlobalMessageContainer>
                <Alert severity={severity} onClose={handleClose}>
                    <AlertTitle>{title}</AlertTitle>
                    <span dangerouslySetInnerHTML={{ __html: content }} />
                </Alert>
            </GlobalMessageContainer>
        </Slide>
    );
};

export default GlobalMessage;
