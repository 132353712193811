import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    DateRange,
    LocalizationProvider,
    SingleInputDateRangeField as MuiSingleInputDateRangeField,
} from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
    IconButton,
    InputAdornment,
    inputAdornmentClasses,
    inputBaseClasses,
    outlinedInputClasses,
    styled,
} from '@mui/material';
import { applyOpacityToHex } from '@nuix/common-ui';
import { MuiClearIcon, MuiTodayIcon } from '@nuix/nomi-icons';
import { Dayjs } from 'dayjs';
import colors from '../../theme/colors';
import { DEFAULT_DATE_FORMAT } from '../../utils/date-utils';
import DateRangePickerPopper from './DateRangePickerPopper';

const SingleInputDateRangeField = styled(MuiSingleInputDateRangeField)({
    overflow: 'hidden',
    [`&:has(.${inputBaseClasses.root}.${outlinedInputClasses.root}.Mui-focused)`]: {
        borderColor: colors.primary60,
        borderRadius: '4px',
        outline: `3px solid ${applyOpacityToHex(colors.primary60, 0.24)}`,
    },
    [`.${inputBaseClasses.root}`]: {
        padding: '4px 0 4px 8px',
        height: '32px',
        '> svg': {
            color: colors.secondary50,
        },
    },
    [`.${inputBaseClasses.input}`]: {
        padding: '0px',
        height: '24px',
        width: '170px',
        marginLeft: '2px',
    },
    [`.${inputAdornmentClasses.positionEnd}`]: {
        width: '24px',
    },
    svg: {
        height: '16px',
        width: '16px',
    },
});

type OwnProps = {
    fromDate: Dayjs | null;
    onFromDateChange: Function;
    onToDateChange: Function;
    toDate: Dayjs | null;
};

const SingleInputDateRangePicker: FC<OwnProps> = ({ fromDate, onFromDateChange, onToDateChange, toDate }) => {
    const { t } = useTranslation();

    const [dateRangeValue, setDateRangeValue] = useState<DateRange<Dayjs>>([fromDate, toDate]);
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(prev => !prev);
    };

    const onAccept = useCallback(() => {
        onFromDateChange(dateRangeValue[0]);
        const endDay = dateRangeValue[1];
        onToDateChange(endDay ? endDay.endOf('day') : endDay);
        setOpen(false);
    }, [dateRangeValue, onFromDateChange, onToDateChange, setOpen]);

    const handleClear = useCallback(() => {
        onFromDateChange(null);
        onToDateChange(null);
        setDateRangeValue([null, null]);
        setOpen(false);
    }, [onFromDateChange, onToDateChange, setOpen]);

    const onCancel = useCallback(() => {
        setDateRangeValue([fromDate, toDate]);
        setOpen(false);
    }, [fromDate, toDate, setDateRangeValue, setOpen]);

    const disabledClear = useMemo(() => !dateRangeValue[0] && !dateRangeValue[1], [dateRangeValue]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <SingleInputDateRangeField
                onClick={handleClick}
                value={dateRangeValue}
                onChange={setDateRangeValue}
                format={DEFAULT_DATE_FORMAT.toUpperCase()}
                dateSeparator={t('single-input-date-range-picker.to')}
                slotProps={{
                    textField: {
                        InputProps: {
                            startAdornment: <MuiTodayIcon />,
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton
                                        edge='end'
                                        title={t('single-input-date-range-picker.clear-value')}
                                        onClick={handleClear}
                                        disabled={disabledClear}
                                    >
                                        <MuiClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        },
                        inputProps: {
                            'aria-label': t('single-input-date-range-picker.date-range-picker'),
                        },
                    },
                }}
            />
            <DateRangePickerPopper
                anchorEl={anchorEl}
                onAccept={onAccept}
                onCancel={onCancel}
                onChange={setDateRangeValue}
                open={open}
                value={dateRangeValue}
            />
        </LocalizationProvider>
    );
};

export default SingleInputDateRangePicker;
