import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Auth from '../../auth/auth';
import Session from '../../session/session';

const ProtectedRoute = ({ element: Component, ...rest }) => {
    const isAuthenticated = Auth.isAuthenticated();
    const location = useLocation();

    if (isAuthenticated) {
        return <Component {...rest} />;
    }

    Session.setLocation(`${window.location.origin}${location.pathname}`);
    return <Navigate to='/login' />;
};

export default ProtectedRoute;
