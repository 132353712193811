import { reducer as activeSessions } from './active-sessions/activeSessions-slice';
import { reducer as auditReportVerification } from './audit-report-verification/auditReportVerification-slice';
import { reducer as consumptionByDongle } from './consumption-by-dongle/consumptionByDongle-slice';
import { reducer as globalMessages } from './global-messages/globalMessages-slice';
import { reducer as moduleSelection } from './module-selection/moduleSelection-slice';

const reducer = {
    activeSessions,
    auditReportVerification,
    consumptionByDongle,
    globalMessages,
    moduleSelection,
};

export default reducer;
