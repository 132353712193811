import React from 'react';
import ModalDialog from '../views/dialog/ModalDialog';

export const handleError = (error, i18n) => {
    const message = error?.graphQLErrors?.[0]?.message || error?.message || error;
    if (message && message.toLowerCase().includes('access is denied')) {
        return (
            <ModalDialog
                title={i18n.t('error-utils.title.unauthorised-access')}
                message={i18n.t('error-utils.message.you-do-not-have-permissions')}
            />
        );
    }
    return <ModalDialog title={i18n.t('error-utils.title.error')} message={message} />;
};
