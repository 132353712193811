import React, { useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@mui/material/styles';
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import Auth from '../auth/auth';
import Auth_Http from '../auth/auth-http';
import Config from '../configs/config';
import Session from '../session/session';
import { getNuixTheme } from '../theme/nuixTheme';
import { getAuthHeader } from '../utils/fetch-utils';
import Login from '../views/login/Login';
import { Callback } from '../views/uaa-callback/Callback';
import ProtectedRoute from '../views/route/ProtectedRoute';
import MainBox from '../views/main-box/MainBox';

const { apis } = Config;

const cache = new InMemoryCache();

const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
};

const enchancedFetch = (url, init) => {
    return fetch(url, {
        ...init,
        headers: {
            ...init.headers,
            'Access-Control-Allow-Origin': '*',
            ...getAuthHeader(),
        },
    }).then(response => {
        if (Auth_Http.isUnauthenticated(response)) {
            Session.setLocation(window.location.href);
            Auth.handleLogin();
        } else {
            return response;
        }
    });
};

const link = new HttpLink({
    uri: `${apis.server}`,
    credentials: 'include',
    fetchOptions: {
        mode: 'cors',
    },
    fetch: enchancedFetch,
});

const client = new ApolloClient({
    cache,
    defaultOptions,
    link,
});

export default function App() {
    const { i18n } = useTranslation();
    const theme = useMemo(() => getNuixTheme(i18n.language), [i18n.language]);

    return (
        <ApolloProvider client={client}>
            <ThemeProvider theme={theme}>
                <Router>
                    <Routes>
                        <Route path='/login' element={<Login />} />
                        <Route path='/callback' element={<Callback />} />
                        <Route path='*' element={<ProtectedRoute element={MainBox} />} />
                    </Routes>
                </Router>
            </ThemeProvider>
        </ApolloProvider>
    );
}
