// TODO: make this info directly available, without authentication (same as in Admin Portal)
const config = {
    apis: {
        server: process.env.REACT_APP_API_BASE_URL,
        restServer: process.env.REACT_APP_API_BASE_URL?.replace('graphql', 'v1'),
        licenceServer: process.env.REACT_APP_LICENCE_SERVER_API_BASE_URL,
        uaa: process.env.REACT_APP_UAA_BASE_URL,
        clientId: process.env.REACT_APP_CLIENT_ID,
    },
};

export default config;
