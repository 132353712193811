import React from 'react';
import { IconButton } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { MuiLogoutIcon } from '@nuix/nomi-icons';
import Session from '../session/session';
import { getDisplayDateTime } from '../utils/date-utils';
import { getDisplayUsername, getQuantityAndUnit, getValueOrNA } from '../utils/activeSession-utils';
import { Direction, PageSortType } from './Pagination';

export const DEFAULT_PAGE_SORT: PageSortType = {
    fieldName: 'lastAlive',
    direction: Direction.DESC,
};

export const QUANTITY_METRICS = {
    CORE: {
        displayQuantityAndUnit: (quantity: number, i18n) =>
            i18n.t('active-session.quantity-metrics.coreWithCount', { count: quantity }),
        value: 'CORE',
    },
    USER: {
        displayQuantityAndUnit: (quantity: number, i18n) =>
            i18n.t('active-session.quantity-metrics.userWithCount', { count: quantity }),
        value: 'USER',
    },
};

type ProductProfileType = {
    displayId: string;
    metricType: string;
    name: string;
    skuCode: string;
};

type LicenceType = {
    displayId: string;
    id: number;
    productProfile: ProductProfileType;
};

type UserType = {
    id: number;
    username: string;
};

export type ActiveSessionRow = {
    acquiredAt: string;
    lastAlive: string;
    licence: LicenceType;
    localUsername?: string;
    quantity: number;
    sessionToken: string;
    user: UserType;
    privateIpAddress?: string;
    machineId?: string;
};

const renderReleaseConfirmationCell = (
    params: GridRenderCellParams<ActiveSessionRow>,
    i18n,
    showReleaseConfirmationPopup
) => {
    const { row } = params;
    return (
        <div title={i18n.t('active-session.render-release-confirmation-cell.release-license')}>
            <IconButton onClick={() => showReleaseConfirmationPopup(row)} size='small'>
                <MuiLogoutIcon />
            </IconButton>
        </div>
    );
};

export const getActiveSessionColumns = (i18n, showReleaseConfirmationPopup): GridColDef<ActiveSessionRow>[] => [
    {
        field: 'userUsername',
        headerName: i18n.t('active-session.get-active-session-columns.user-name-column.header-name.user-name'),
        flex: 1,
        resizable: true,
        valueGetter: (value, row) => getDisplayUsername(row),
    },
    {
        field: 'licenceProductProfileName',
        headerName: i18n.t('active-session.get-active-session-columns.license-name-column.header-name.license-name'),
        flex: 1,
        resizable: true,
        valueGetter: (value, row) => row.licence.productProfile.name,
    },
    {
        field: 'licenceDisplayId',
        headerName: i18n.t('active-session.get-active-session-columns.license-id-column.header-name.license-id'),
        flex: 1,
        resizable: true,
        valueGetter: (value, row) => row.licence.displayId,
    },
    {
        field: 'quantity',
        headerName: i18n.t('active-session.get-active-session-columns.quantity-column.header-name.quantity'),
        resizable: true,
        type: 'number',
        valueFormatter: (value, row) => getQuantityAndUnit(row, i18n),
    },
    {
        field: 'sessionToken',
        headerName: i18n.t('active-session.get-active-session-columns.session-id-column.header-name.session-id'),
        flex: 1.5,
        resizable: true,
    },
    ...(Session.displayMachineDetailsInActiveSessions()
        ? [
              {
                  field: 'privateIpAddress',
                  headerName: i18n.t(
                      'active-session.get-active-session-columns.ip-address-column.header-name.ip-address'
                  ),
                  flex: 1,
                  resizable: true,
                  valueFormatter: value => getValueOrNA(value, i18n),
              },
              {
                  field: 'machineId',
                  headerName: i18n.t(
                      'active-session.get-active-session-columns.machine-id-column.header-name.machine-id'
                  ),
                  flex: 1,
                  resizable: true,
                  valueFormatter: value => getValueOrNA(value, i18n),
              },
          ]
        : []),
    {
        field: 'acquiredAt',
        headerName: i18n.t(
            'active-session.get-active-session-columns.session-started-column.header-name.session-started'
        ),
        flex: 1,
        type: 'dateTime',
        resizable: true,
        valueFormatter: value => getDisplayDateTime(value),
    },
    {
        field: 'lastAlive',
        headerName: i18n.t('active-session.get-active-session-columns.last-active-column.header-name.last-active'),
        flex: 1,
        type: 'dateTime',
        resizable: true,
        valueFormatter: value => getDisplayDateTime(value),
    },
    // All users will be able to release licence sessions from their own orgs.
    // In practice, though, Licence Users will only see their own sessions, so will only be able to release those.
    // That is the same behaviour as in the WS x Licence Server integration currently. More details in the ticket comments.
    ...(Session.hasPermission('cls.p.lic.acq')
        ? [
              {
                  field: 'id',
                  headerName: '',
                  sortable: false,
                  width: 40,
                  renderCell: (params: GridRenderCellParams<ActiveSessionRow>) =>
                      renderReleaseConfirmationCell(params, i18n, showReleaseConfirmationPopup),
              },
          ]
        : []),
];
