import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { i18n } from '@nuix/common-ui';
import { OptionType } from '../../components/filter-with-select-all/FilterWithSelectAll';
import { ConsumptionUsageSummaryType, DEFAULT_PAGE_SORT } from '../../constants/Consumption';
import { DEFAULT_PAGE_SIZE, PageSortType } from '../../constants/Pagination';
import config from '../../configs/config';
import { downloadFile } from '../../utils/fetch-utils';
import { actions as globalMessagesActions } from '../global-messages/globalMessages-slice';

type ConsumptionByDongleState = Readonly<{
    consumptionUsageSummary: ConsumptionUsageSummaryType;
    dongleOptions: OptionType[];
    downloading: boolean;
    pageSize: number;
    pageSort: PageSortType;
}>;

const initialState: ConsumptionByDongleState = {
    consumptionUsageSummary: {
        consumptionUsages: [],
        totalCases: 0,
        totalProcessed: 0,
    },
    dongleOptions: [],
    downloading: false,
    pageSize: DEFAULT_PAGE_SIZE,
    pageSort: DEFAULT_PAGE_SORT,
};

const exportConsumptionByDongle = createAsyncThunk(
    'consumptionByDongle/export',
    async (params: Record<string, any> = {}, thunkAPI) => {
        const queryParameters = {
            licenceModes: 'OFFLINE',
            fields: 'generatedAt,dongleName,caseName,batchLoadId,productVersion,originalIncludedSize,jobSize',
            page: 0,
            size: 30000,
            ...params,
        };
        try {
            await downloadFile(`${config.apis.restServer}/dataVolumeTelemetries/exportCSV`, queryParameters);
        } catch (error) {
            thunkAPI.dispatch(
                globalMessagesActions.globalMessageAdded({
                    severity: 'error',
                    title: i18n.t('consumption-by-dongle-slice.export-consumption-by-dongle.export-failed'),
                    content: error?.message || '',
                })
            );
        }
    }
);

const consumptionByDongleSlice = createSlice({
    name: 'consumptionByDongle',
    initialState,
    reducers: {
        setConsumptionUsageSummary(state, { payload }) {
            state.consumptionUsageSummary = payload;
        },
        setDongleOptions(state, { payload }) {
            state.dongleOptions = payload.map(name => ({ label: name, value: name }));
        },
        updatePageSize(state, { payload }) {
            state.pageSize = payload;
        },
        updatePageSort(state, { payload }) {
            state.pageSort = payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(exportConsumptionByDongle.pending, state => {
                state.downloading = true;
            })
            .addCase(exportConsumptionByDongle.fulfilled, state => {
                state.downloading = false;
            });
    },
});

const { actions, reducer } = consumptionByDongleSlice;
const combinedActions = {
    ...actions,
    exportConsumptionByDongle,
};

export { combinedActions as actions, initialState, reducer };
