import React, { FC } from 'react';
import { LinearProgress as MuiLinearProgress, linearProgressClasses, styled } from '@mui/material';
import colors from '../../theme/colors';

const PREFIX = 'Modal';
const classes = {
    displayValue: `${PREFIX}__display-value`,
    progressBar: `${PREFIX}__progress-bar`,
};

const Container = styled('div')({
    [`.${classes.displayValue}`]: {
        fontSize: '12px',
        lineHeight: '16px',
        marginBottom: '6px',
    },
    [`.${classes.progressBar}`]: {
        borderRadius: '4px',
        backgroundColor: colors.ui40,
        [`.${linearProgressClasses.bar}`]: {
            background: `linear-gradient(to right, ${colors.primary30}, ${colors.primary50})`,
        },
    },
});

type OwnProps = {
    progress?: number;
};

const LinearProgress: FC<OwnProps> = ({ progress = 0 }) => {
    return (
        <Container>
            <div className={classes.displayValue}>{progress}%</div>
            <MuiLinearProgress variant='determinate' value={progress} className={classes.progressBar} />
        </Container>
    );
};

export default LinearProgress;
